import { HttpResponse, HttpStatus, BASE_URL, AUTH_DEBUG } from "./default";

const blogCategory = async (name, token) => {
    const url = `${BASE_URL}/blog/categories/`
    var errorMessage;
    try {
        const options = {
            method: 'POST',
            credentials: 'include',
            headers: {
                jwt: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            body: JSON.stringify({ name })
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("BlogAPI::blogCategory(): ", data);
            return new HttpResponse(HttpStatus.OK, data);
        } else {
            errorMessage = await response.json();
            throw new Error("Error on blogCategory()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, errorMessage);
    }
}

const getCategories = async (token, page) => {
    const url = `${BASE_URL}/blog/categories/?page=${page}`
    try {
        const options = {
            method: 'GET',
            credentials: 'include',
            headers: {
                jwt: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("BlogAPI::getCategories(): ", data);
            return new HttpResponse(HttpStatus.OK, data);
        } else throw new Error("Error on getCategories()");
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, null);
    }
}

const getListCategories = async () => {
    const url = `${BASE_URL}/blog/listCategories/`
    try {
        const options = {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("BlogAPI::getListCategories(): ", data);
            return new HttpResponse(HttpStatus.OK, data);
        } else throw new Error("Error on getListCategories()");
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, null);
    }
}

const editCategory = async (idCategory, name, token) => {
    const url = `${BASE_URL}/blog/categories/${idCategory}/`
    var errorMessage;
    try {
        const options = {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                jwt: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            body: JSON.stringify({ name })
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("BlogAPI::blogCategory(): ", data);
            return new HttpResponse(HttpStatus.OK, data);
        } else {
            errorMessage = await response.json();
            throw new Error("Error on blogCategory()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, errorMessage);
    }
}

const deleteCategory = async (idCategory, token) => {
    const url = `${BASE_URL}/blog/categories/${idCategory}/`
    try {
        const options = {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                jwt: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
        }

        const response = await fetch(url, options);
        if (response.ok) {
            return new HttpResponse(HttpStatus.OK, null);
        } else {
            throw new Error("Error on blogCategory()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, null);
    }
}

const blogAuthor = async (name, token) => {
    const url = `${BASE_URL}/blog/authors/`
    var errorMessage;
    try {
        const options = {
            method: 'POST',
            credentials: 'include',
            headers: {
                jwt: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            body: JSON.stringify({ name })
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("BlogAPI::blogAuthor(): ", data);
            return new HttpResponse(HttpStatus.OK, data);
        } else {
            errorMessage = await response.json();
            throw new Error("Error on blogAuthor()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, errorMessage);
    }
}

const getAuhtor = async (token, page) => {
    const url = `${BASE_URL}/blog/authors/?page=${page}`
    try {
        const options = {
            method: 'GET',
            credentials: 'include',
            headers: {
                jwt: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("BlogAPI::getAuhtor(): ", data);
            return new HttpResponse(HttpStatus.OK, data);
        } else throw new Error("Error on getAuhtor()");
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, null);
    }
}

const getListAuhtor = async () => {
    const url = `${BASE_URL}/blog/listAuthors/`
    try {
        const options = {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("BlogAPI::getAuhtor(): ", data);
            return new HttpResponse(HttpStatus.OK, data);
        } else throw new Error("Error on getAuhtor()");
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, null);
    }
}

const editAuthor = async (idCategory, name, token) => {
    const url = `${BASE_URL}/blog/authors/${idCategory}/`
    var errorMessage;
    try {
        const options = {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                jwt: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            body: JSON.stringify({ name })
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("BlogAPI::editAuthor(): ", data);
            return new HttpResponse(HttpStatus.OK, data);
        } else {
            errorMessage = await response.json();
            throw new Error("Error on editAuthor()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, errorMessage);
    }
}

const deleteAuthor = async (idCategory, token) => {
    const url = `${BASE_URL}/blog/authors/${idCategory}/`
    try {
        const options = {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                jwt: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
        }

        const response = await fetch(url, options);
        if (response.ok) {
            return new HttpResponse(HttpStatus.OK, null);
        } else {
            throw new Error("Error on deleteAuthor()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, null);
    }
}

const blogPosts = async (formData, token) => {
    const url = `${BASE_URL}/blog/posts/`;
    var errorMessage;
    try {
        const options = {
            method: 'POST',
            credentials: 'include',
            headers: {
                jwt: token,
                Accept: 'application/json'
            },
            body: formData
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("BlogAPI::getPosts(): ", data);
            return new HttpResponse(HttpStatus.OK, data);
        } else {
            errorMessage = await response.json();
            throw new Error("Error on getPosts()");
        }
    } catch (error) {
        console.warn(error);
        return new HttpResponse(HttpStatus.ERROR, errorMessage);
    }
}

const getPosts = async (token, page) => {
    const url = `${BASE_URL}/blog/posts/?page=${page}`
    try {
        const options = {
            method: 'GET',
            credentials: 'include',
            headers: {
                jwt: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("BlogAPI::getPosts(): ", data);
            return new HttpResponse(HttpStatus.OK, data);
        } else throw new Error("Error on getPosts()");
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, null);
    }
}

// Sem autentificação get Simples (id, title, resume)
const getListPosts = async () => {
    const url = `${BASE_URL}/blog/listPosts/`
    try {
        const options = {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("BlogAPI::getPosts(): ", data);
            return new HttpResponse(HttpStatus.OK, data);
        } else throw new Error("Error on getPosts()");
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, null);
    }
}

const getOnePost = async (idPost, token) => {
    const url = `${BASE_URL}/blog/posts/${idPost}/`
    try {
        const options = {
            method: 'GET',
            credentials: 'include',
            headers: {
                jwt: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("BlogAPI::getOnePost(): ", data);
            return new HttpResponse(HttpStatus.OK, data);
        } else throw new Error("Error on getOnePost()");
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, null);
    }
}

const editPost = async (idPost, formData, token) => {
    const url = `${BASE_URL}/blog/posts/${idPost}/`
    var errorMessage;
    try {
        const options = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                jwt: token,
                Accept: 'application/json'
            },
            body: formData
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("BlogAPI::editPost(): ", data);
            return new HttpResponse(HttpStatus.OK, data);
        } else {
            errorMessage = await response.json();
            throw new Error("Error on editPost()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, errorMessage);
    }
}

const deletePost = async (idPost, token) => {
    const url = `${BASE_URL}/blog/posts/${idPost}/`
    try {
        const options = {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                jwt: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
        }

        const response = await fetch(url, options);
        if (response.ok) {
            return new HttpResponse(HttpStatus.OK, null);
        } else {
            throw new Error("Error on deletePost()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, null);
    }
}

// ---------------------------------------------Banners---------------------------------------------

const getBannersBlog = async (token, page) => {
    const url = `${BASE_URL}/blog/bannerBlog/`
    try {
        const options = {
            method: 'GET',
            credentials: 'include',
            headers: {
                jwt: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("BlogAPI::getPosts(): ", data);
            return new HttpResponse(HttpStatus.OK, data);
        } else throw new Error("Error on getPosts()");
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, null);
    }
}

const blogBanners = async (formData, token) => {
    const url = `${BASE_URL}/blog/bannerBlog/`;
    var errorMessage;
    try {
        const options = {
            method: 'POST',
            credentials: 'include',
            headers: {
                jwt: token,
                Accept: 'application/json'
            },
            body: formData
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("BlogAPI::getPosts(): ", data);
            return new HttpResponse(HttpStatus.OK, data);
        } else {
            errorMessage = await response.json();
            throw new Error("Error on getPosts()");
        }
    } catch (error) {
        console.warn(error);
        return new HttpResponse(HttpStatus.ERROR, errorMessage);
    }
}

const editBlogBanners = async (idBanner, formData, token) => {
    const url = `${BASE_URL}/blog/bannerBlog/${idBanner}/`
    var errorMessage;
    try {
        const options = {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                jwt: token,
                Accept: 'application/json'
            },
            body: formData
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("BlogAPI::editPost(): ", data);
            return new HttpResponse(HttpStatus.OK, data);
        } else {
            errorMessage = await response.json();
            throw new Error("Error on editPost()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, errorMessage);
    }
}

const deleteBlogBanners = async (idBanner, token) => {
    const url = `${BASE_URL}/blog/bannerBlog/${idBanner}/`
    try {
        const options = {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                jwt: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
        }

        const response = await fetch(url, options);
        if (response.ok) {
            return new HttpResponse(HttpStatus.OK, null);
        } else {
            throw new Error("Error on deletePost()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, null);
    }
}

export const BlogAPI = {
    blogCategory,
    getCategories,
    getListCategories,
    editCategory,
    deleteCategory,
    blogAuthor,
    getAuhtor,
    getListAuhtor,
    editAuthor,
    deleteAuthor,
    blogPosts,
    getPosts,
    getListPosts,
    getOnePost,
    editPost,
    deletePost,
    getBannersBlog,
    blogBanners,
    editBlogBanners,
    deleteBlogBanners,
}
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import styles from "./style.module.scss";
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../contexts/authContext';
import { toast } from 'react-toastify';
import { usePostOnePartner } from '../../api/PartnerAPI/partnerClient';


export default function SectionCompanyGuruAddress({ data }) {
    const { partner_id } = useParams();
    const { token } = useAuthContext();
    const [isEditing, setIsEditing] = useState(false);

    const { mutate: postOnePartner } = usePostOnePartner(partner_id);

    const [formValues, setFormValues] = useState({
        cep: '',
        state: '',
        city: '',
        neighborhood: '',
        street: '',
        number: '',
        complement: '',
    });
    const [originalValues, setOriginalValues] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (data) {
            setFormValues({
                cep: data.a_postal_code || '',
                state: data.a_state || '',
                city: data.a_city || '',
                neighborhood: data.a_neighborhood || '',
                street: data.a_street_name || '',
                number: data.a_number || '',
                complement: data.a_complement || '',
            });
            setOriginalValues({
                cep: data.a_postal_code || '',
                state: data.a_state || '',
                city: data.a_city || '',
                neighborhood: data.a_neighborhood || '',
                street: data.a_street_name || '',
                number: data.a_number || '',
                complement: data.a_complement || '',
            });
        }
    }, [data]);

    const validateForm = () => {
        const newErrors = {};
        if (!formValues.cep) newErrors.cep = 'O CEP é obrigatório';
        if (!formValues.state) newErrors.state = 'O estado é obrigatório';
        if (!formValues.city) newErrors.city = 'A cidade é obrigatória';
        if (!formValues.neighborhood) newErrors.neighborhood = 'O bairro é obrigatório';
        if (!formValues.street) newErrors.street = 'A rua é obrigatória';
        if (!formValues.number) newErrors.number = 'O número é obrigatório';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [id]: value
        }));
    };

    const handleSubmitAddress = (e) => {
        e.preventDefault();
        
        if (!validateForm()) {
            return;
        }

        const changedValues = {};
        Object.keys(formValues).forEach(key => {
            if (formValues[key] !== originalValues[key]) {
                changedValues[key] = formValues[key];
            }
        });
    
        const formData = new FormData();
    
        if (changedValues.cep) formData.append('a_postal_code', changedValues.cep);
        if (changedValues.state) formData.append('a_state', changedValues.state);
        if (changedValues.city) formData.append('a_city', changedValues.city);
        if (changedValues.neighborhood) formData.append('a_neighborhood', changedValues.neighborhood);
        if (changedValues.street) formData.append('a_street_name', changedValues.street);
        if (changedValues.number) formData.append('a_number', changedValues.number);
        if (changedValues.complement) formData.append('a_complement', changedValues.complement);

        if (Object.keys(changedValues).length === 0) {
            handleCancel();
            return;
        }

        try {
            postOnePartner({ formData, partner_id, token });
            toast.success('Dados alterados com sucesso!.');
            handleCancel();
        } catch (error) {
            toast.error('Erro ao atualizar dados, tente novamente!');
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    return (
        <section id={styles.SectionCompanyGuruAddress}>
            <Container>
                <Row className={styles.rowTitleAddress}>
                    <Col xs={6} md={6}>
                        <p className={styles.title}>Endereço</p>
                    </Col>
                    <Col xs={6} md={6} className={styles.colButtonEdit}>
                        {isEditing ? (
                            <Button className={styles.buttonCancel} onClick={handleCancel}>
                                Cancelar
                            </Button>
                        ) : (
                            <Button className={styles.buttonEdit} onClick={() => setIsEditing(true)}>
                                Editar
                            </Button>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Card className={styles.cardAddress}>
                        <Card.Body>
                            <Form onSubmit={handleSubmitAddress} className={styles.form}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Form.Group controlId="cep">
                                            <Form.Label className={styles.formTitle}>CEP</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Digite seu CEP"
                                                className={styles.formControl}
                                                value={formValues.cep}
                                                onChange={handleChange}
                                                disabled={!isEditing}
                                            />
                                            {errors.cep && <Form.Text className="text-danger">{errors.cep}</Form.Text>}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Form.Group controlId="street">
                                            <Form.Label className={styles.formTitle}>Rua/Avenida</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Digite sua rua ou avenida"
                                                className={styles.formControl}
                                                value={formValues.street}
                                                onChange={handleChange}
                                                disabled={!isEditing}
                                            />
                                            {errors.street && <Form.Text className="text-danger">{errors.street}</Form.Text>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Form.Group controlId="number">
                                            <Form.Label className={styles.formTitle}>Número</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Digite o número"
                                                className={styles.formControl}
                                                value={formValues.number}
                                                onChange={handleChange}
                                                disabled={!isEditing}
                                            />
                                            {errors.number && <Form.Text className="text-danger">{errors.number}</Form.Text>}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Form.Group controlId="neighborhood">
                                            <Form.Label className={styles.formTitle}>Bairro</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Digite seu bairro"
                                                className={styles.formControl}
                                                value={formValues.neighborhood}
                                                onChange={handleChange}
                                                disabled={!isEditing}
                                            />
                                            {errors.neighborhood && <Form.Text className="text-danger">{errors.neighborhood}</Form.Text>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Form.Group controlId="city">
                                            <Form.Label className={styles.formTitle}>Cidade</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Digite sua cidade"
                                                className={styles.formControl}
                                                value={formValues.city}
                                                onChange={handleChange}
                                                disabled={!isEditing}
                                            />
                                            {errors.city && <Form.Text className="text-danger">{errors.city}</Form.Text>}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Form.Group controlId="state">
                                            <Form.Label className={styles.formTitle}>Estado</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Digite seu estado"
                                                className={styles.formControl}
                                                value={formValues.state}
                                                onChange={handleChange}
                                                disabled={!isEditing}
                                            />
                                            {errors.state && <Form.Text className="text-danger">{errors.state}</Form.Text>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="complement">
                                            <Form.Label className={styles.formTitle}>Complemento</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Digite o complemento"
                                                className={styles.formControl}
                                                value={formValues.complement}
                                                onChange={handleChange}
                                                disabled={!isEditing}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {isEditing && (
                                    <Row className={styles.rowButton}>
                                        <Col xs={6} md={6} className={styles.colButtonCancel}>
                                            <Button className={styles.buttonCancel} onClick={handleCancel}>
                                                Cancelar
                                            </Button>
                                        </Col>
                                        <Col xs={6} md={6} className={styles.colButtonSend}>
                                            <Button className={styles.button} type="submit">
                                                Enviar
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </Form>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        </section>
    );
}

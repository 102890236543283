import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, ListGroup, Button, Modal, Badge } from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css';
import styles from './style.module.scss';
import { BlogAPI } from '../../api/blog';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/pagination';
import { formatDate, truncateText } from '../../utils/utils';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useAuthContext } from '../../contexts/authContext';

export default function BlogPostsPage() {
    const { token } = useAuthContext();
    const navigate = useNavigate();
    const [posts, setPosts] = useState([]);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [postToDelete, setPostToDelete] = useState(null);

    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 10; 

    const getPostsData = useCallback(async (page = 1) => {
        const responsePosts = await BlogAPI.getPosts(token, page);
        if (responsePosts.status === 200) {
            setPosts(responsePosts.data.results);
            setTotalPages(Math.ceil(responsePosts.data.count / itemsPerPage));
        }
    }, [token]);

    const handleDelete = async () => {
        try {
            const response = await BlogAPI.deletePost(postToDelete, token);
            if (response.status === 200) {
                getPostsData();
                handleDeleteModalClose();
            } else {
                console.error('Erro ao deletar Post:', response);
            }
        } catch (error) {
            console.error('Erro ao deletar Post:', error.message);
        }
    };

    const handleDeleteModalOpen = (postId) => {
        setPostToDelete(postId);
        setDeleteModalOpen(true);
    };

    const handleDeleteModalClose = () => {
        setPostToDelete(null);
        setDeleteModalOpen(false);
    };

    const handlePageChange = (page) => {
        getPostsData(page);
    };

    useEffect(() => {
        getPostsData();
    }, [getPostsData]);

    return (
        <HelmetProvider>
            <section id={styles.BlogPostsPage}>
                <Helmet>
                    <title>Posts - Blog</title>
                </Helmet>
                <Container>
                    <Row>
                        <Col>
                            <p className={styles.title}>Posts do Blog</p>
                        </Col>
                        <Col className={styles.colButton}>
                            <Button className={styles.buttonPost} onClick={() => navigate('/posts/criar')}>
                                Criar Post
                            </Button>
                        </Col>
                    </Row>
                    <Row className={styles.rowDescription}>
                        <Col lg={5} md={5} sm={5} className={styles.textTitle}>
                            Título
                        </Col>
                        <Col lg={2} md={2} sm={2} className={styles.subText}>
                            Criado
                        </Col>
                        <Col lg={2} md={2} sm={2} className={styles.subText}>
                            Modificado
                        </Col>
                        <Col lg={1} md={1} sm={1} className={styles.subText}>
                            Status
                        </Col>
                        <Col lg={2} md={2} sm={2}>
                        </Col>
                    </Row>
                    <Row>
                        <ListGroup className={styles.listGroup}>
                            {posts && posts.length > 0 &&
                                posts.map((post) => (
                                    <ListGroup.Item key={post.id}>
                                        <Row>
                                            <Col lg={5} md={5} sm={5}>
                                                <p className={styles.text}>{truncateText(post.title, 65)}</p>
                                            </Col>
                                            <Col lg={2} md={2} sm={2}>
                                                <p className={styles.date}>{formatDate(post.created)}</p>
                                            </Col>
                                            <Col lg={2} md={2} sm={2}>
                                                <p className={styles.date}>{formatDate(post.modified)}</p>
                                            </Col>
                                            <Col lg={1} md={1} sm={1} className={styles.colBadge}>
                                                <Badge
                                                    bg={post.is_published ? "success" : "danger"}
                                                    className={styles.badge}
                                                >
                                                    {post.is_published ? "Publicado" : "Em andamento"}
                                                </Badge>
                                            </Col>
                                            <Col lg={2} md={2} sm={2} className={styles.rowIcon}>
                                                <Link to={`/posts/editar/${post.id}`}>
                                                    <FaEdit className={styles.iconEdit} />
                                                </Link>
                                                <FaTrash className={styles.iconDelete} onClick={() => handleDeleteModalOpen(post.id)} />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                ))
                            }
                        </ListGroup>
                    </Row>
                    <Row>
                        <Pagination totalPages={totalPages} onPageChange={handlePageChange}/>
                    </Row>
                </Container>
                <Modal show={deleteModalOpen} onHide={handleDeleteModalClose} centered className={styles.modal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar Exclusão</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Tem certeza de que deseja excluir este post?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className={styles.buttonCancel} onClick={handleDeleteModalClose}>
                            Cancelar
                        </Button>
                        <Button className={styles.buttonDelete} onClick={handleDelete}>
                            Excluir
                        </Button>
                    </Modal.Footer>
                </Modal>
            </section>
        </HelmetProvider>
    );
}

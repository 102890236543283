import React, { useState, useEffect } from 'react';
import { Button, Offcanvas, Nav, Container, Dropdown, Spinner, Alert, Row, Col } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaClock, FaToolbox, FaUser } from 'react-icons/fa';
import { BiCategory, BiMenu, BiPhotoAlbum } from 'react-icons/bi';
import { BsFilePostFill, BsNewspaper, BsPeopleFill } from 'react-icons/bs';
import { RiCalendarScheduleFill, RiProfileFill } from 'react-icons/ri';
import { TiGroup } from 'react-icons/ti';
import styles from './style.module.scss';
import { ProtectedRoute } from '../../contexts/protectedRoute';
import { Roles } from '../../api/default';
import { usePartnerResumeData } from '../../api/PartnerAPI/partnerClient';
import { useAuthContext } from '../../contexts/authContext';
import { GrClose } from 'react-icons/gr';

export default function Sidebar() {
    const { token, user } = useAuthContext();
    const { data, isLoading, isError } = usePartnerResumeData(user?.id, token);
    const [show, setShow] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 991.98);
    const [selectedPartner, setSelectedPartner] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (data?.partner && data.partner.length > 0) {
            setSelectedPartner(data.partner[0]); 
        }
    }, [data]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 991.98);
    };

    const handleNavLinkClick = () => {
        handleClose();
    };

    const handleSelectPartner = (partner) => {
        setSelectedPartner(partner);
        handleClose();
        navigate('/painel')
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const MenuContent = () => (
        <Container>
            <ProtectedRoute roles={[Roles.BLOGGER_GURU]}>
                <Nav className={styles.nav}>
                    <h2 className={styles.title}>Blog</h2>
                    <Nav.Item className={styles.navItem}>
                        <NavLink
                            to="/bannersBlog"
                            className={({ isActive }) =>
                                isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
                            }
                            onClick={() => handleNavLinkClick()}
                        >
                            <BiPhotoAlbum className={styles.icon} />
                            <span className={styles.text}>Banners</span>
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item className={styles.navItem}>
                        <NavLink
                            to="/categorias"
                            className={({ isActive }) =>
                                isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
                            }
                            onClick={() => handleNavLinkClick()}
                        >
                            <BiCategory className={styles.icon} />
                            <span className={styles.text}>Categorias</span>
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item className={styles.navItem}>
                        <NavLink
                            to="/autores"
                            className={({ isActive }) =>
                                isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
                            }
                            onClick={() => handleNavLinkClick()}
                        >
                            <FaUser className={styles.icon} />
                            <span className={styles.text}>Autores</span>
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item className={styles.navItem}>
                        <NavLink
                            to="/posts"
                            className={({ isActive }) =>
                                isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
                            }
                            onClick={() => handleNavLinkClick()}
                        >
                            <BsFilePostFill className={styles.icon} />
                            <span className={styles.text}>Posts</span>
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item className={styles.navItem}>
                        <NavLink
                            to="/newsLetter"
                            className={({ isActive }) =>
                                isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
                            }
                            onClick={() => handleNavLinkClick()}
                        >
                            <BsNewspaper className={styles.icon} />
                            <span className={styles.text}>Newsletter</span>
                        </NavLink>
                    </Nav.Item>
                </Nav>
            </ProtectedRoute>

            <ProtectedRoute roles={[Roles.PARCEIRO_A_GURU]}>
                <Nav className={styles.nav}>
                    {isLoading ? (
                        <Spinner animation="border" />
                    ) : isError ? (
                        <Alert variant="danger">Erro ao carregar os dados.</Alert>
                    ) : data?.partner && data.partner.length > 0 ? (
                        <>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-partner" className={styles.dropPartner}>
                                    {selectedPartner ? selectedPartner.name : "Selecione a Empresa"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={styles.dropPartnerMenu}>
                                    {data.partner.map((p) => (
                                        <Dropdown.Item
                                            key={p.id}
                                            onClick={() => handleSelectPartner(p)}
                                            className={styles.dropPartnerItem}
                                        >
                                            {p.name}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                            {selectedPartner && (
                                <>
                                    <Nav.Item className={styles.navItem}>
                                        <NavLink
                                            to={`/parceiroGuru/${selectedPartner.id}`}
                                            className={({ isActive }) =>
                                                isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
                                            }
                                            onClick={() => handleNavLinkClick()}
                                        >
                                            <RiProfileFill className={styles.icon} />
                                            <span className={styles.text}>Perfil Empresa</span>
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item className={styles.navItem}>
                                        <NavLink
                                            to={`/horarioGuru/${selectedPartner.id}`}
                                            className={({ isActive }) =>
                                                isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
                                            }
                                            onClick={() => handleNavLinkClick()}
                                        >
                                            <FaClock className={styles.icon} />
                                            <span className={styles.text}>Horário de Funcionamento</span>
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item className={styles.navItem}>
                                        <NavLink
                                            to={`/servicosGuru/${selectedPartner.id}`}
                                            className={({ isActive }) =>
                                                isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
                                            }
                                            onClick={() => handleNavLinkClick()}
                                        >
                                            <FaToolbox className={styles.icon} />
                                            <span className={styles.text}>Serviços</span>
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item className={styles.navItem}>
                                        <NavLink
                                            to={`/colaboradoresGuru/${selectedPartner.id}`}
                                            className={({ isActive }) =>
                                                isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
                                            }
                                            onClick={() => handleNavLinkClick()}
                                        >
                                            <TiGroup className={styles.icon} />
                                            <span className={styles.text}>Colaboradores</span>
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item className={styles.navItem}>
                                        <NavLink
                                            to={`/agendamentosGuru/${selectedPartner.id}`}
                                            className={({ isActive }) =>
                                                isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
                                            }
                                            onClick={() => handleNavLinkClick()}
                                        >
                                            <RiCalendarScheduleFill className={styles.icon} />
                                            <span className={styles.text}>Agendamentos</span>
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item className={styles.navItem}>
                                        <NavLink
                                            to={`/clientesGuru/${selectedPartner.id}`}
                                            className={({ isActive }) =>
                                                isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
                                            }
                                            onClick={() => handleNavLinkClick()}
                                        >
                                            <BsPeopleFill className={styles.icon} />
                                            <span className={styles.text}>Lista de Clientes</span>
                                        </NavLink>
                                    </Nav.Item>
                                </>
                            )}
                        </>
                    ) : <>
                            <Nav.Item className={styles.navItem}>
                                <NavLink
                                    to={`/parceiroGuru/criar`}
                                    className={({ isActive }) =>
                                        isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
                                    }
                                    onClick={() => handleNavLinkClick()}
                                >
                                    <RiProfileFill className={styles.icon} />
                                    <span className={styles.text}>Criar Empresa</span>
                                </NavLink>
                            </Nav.Item>
                        </>
                    }
                </Nav>
            </ProtectedRoute>
        </Container>
    );

    return (
        <>
            {isMobile ? (
                <>
                    <Button className={styles.buttonSidebar} onClick={handleShow}>
                        <BiMenu className={styles.iconMenu} /> Menu
                    </Button>
                    <Offcanvas show={show} onHide={handleClose} id={styles.SideMenu}>
                        <Offcanvas.Header>
                            <Row className={styles.offHeader}>
                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Offcanvas.Title className={styles.offbodyTitle}>GoinGuru</Offcanvas.Title>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={6} xl={6} className={styles.colButtonClose}>
                                    <Button
                                        className={styles.btnClose}
                                        onClick={handleClose}
                                    ><GrClose className={styles.btnCloseIcon}/></Button>
                                </Col>
                            </Row>
                        </Offcanvas.Header>
                        <Offcanvas.Body className={styles.offbody}>
                            <MenuContent />
                        </Offcanvas.Body>
                    </Offcanvas>
                </>
            ) : (
                <div id={styles.SideMenu}>
                    <MenuContent />
                </div>
            )}
        </>
    );
}

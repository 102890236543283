import React, { useState } from 'react';
import { Container, Row, Col, ListGroup, Badge } from 'react-bootstrap';
import styles from "./style.module.scss";
import { useAllEmailNewsLetterData } from '../../api/blogAPI/blogClient';
import Pagination from '../../components/pagination';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useAuthContext } from '../../contexts/authContext';

export default function NewsLetterBlogPage() {
    const { token } = useAuthContext();
    const [page, setPage] = useState(1);
    const itemsPerPage = 10;
    
    const { data, isLoading, isError } = useAllEmailNewsLetterData(token, page);


    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    return (
        <HelmetProvider>
            <section id={styles.NewsLetterBlogPage}>
                <Helmet>
                    <title>NewsLetter - Blog</title>
                </Helmet>
                <Container>
                    <Row>
                        <p className={styles.title}>Emails Cadastrados</p>
                    </Row>
                    <Row>
                        <ListGroup className={styles.listGroup}>
                            {isLoading ? (
                                <p>Carregando...</p>
                            ) : isError ? (
                                <p>Erro ao carregar emails.</p>
                            ) : (
                                data && data.results && data.results.length > 0 ? (
                                    data.results.map((news) => (
                                        <ListGroup.Item key={news.id}>
                                            <Row>
                                                <Col xs={12} md={10}>
                                                    <p>{news.email}</p>
                                                </Col>
                                                <Col xs={12} md={2} className={styles.colBadge}>
                                                    <Badge
                                                        bg={news.is_active ? "success" : "danger"}
                                                        className={styles.badge}
                                                    >
                                                        {news.is_active ? "Ativo" : "Inativo"}
                                                    </Badge>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    ))
                                ) : (
                                    <p>Nenhum email encontrado.</p>
                                )
                            )}
                        </ListGroup>
                    </Row>
                    <Row>
                        {data && (
                            <Pagination
                                totalPages={Math.ceil(data.count / itemsPerPage)}
                                onPageChange={handlePageChange}
                                currentPage={page}
                            />
                        )}
                    </Row>
                </Container>
            </section>
        </HelmetProvider>
    );
}

import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import React from 'react';
import { AuthProvider } from '../contexts/authContext';
import { ProtectedRoute } from '../contexts/protectedRoute';
import { Roles } from '../api/default';
import HomePage from '../pages/home';
import LoginPage from '../pages/login';
import Menu from '../components/menu';
import PainelPage from '../pages/painel';
import BlogCategoryPage from '../pages/blogCategory';
import BlogAuthorPage from '../pages/blogAuthor';
import BlogCreatePosts from '../pages/blogPosts/blogCreatePosts';
import BlogPostsPage from '../pages/blogPosts';
import BlogEditPosts from '../pages/blogPosts/blogEditPosts';
import NotFoundPage from '../pages/notFound';
import BlogPage from '../pages/blog';
import BannersBlogPage from '../pages/bannersBlog';
import PostBlogPage from '../pages/blog/blogPage';
import Footer from '../components/footer';
import NewsLetterBlogPage from '../pages/newsLetterBlog';
import CompanyGuru from '../pages/partnerGuru/company';
import BussinessHourPage from '../pages/partnerGuru/bussinessHour';
import ServicesGuruPage from '../pages/partnerGuru/services';
import ServicesCreatePage from '../pages/partnerGuru/services/createServices';
import EmployeesGuruPage from '../pages/partnerGuru/employees';
import EmployeesCreatePage from '../pages/partnerGuru/employees/createEmployees';
import SchedulesGuruPage from '../pages/partnerGuru/schedules';
import SchedulesCreatePage from '../pages/partnerGuru/schedules/createSchedules';
import Sidebar from '../components/sidebar';
import ServicesEditPage from '../pages/partnerGuru/services/editServices';
import EmployeesEditPage from '../pages/partnerGuru/employees/editEmployees';
import ClientListPage from '../pages/partnerGuru/clientList/clientList';
import CreateCompanyPage from '../pages/partnerGuru/company/createCompany/createCompany';
import SignUpPage from '../pages/signup';
import ClientListCreatePage from '../pages/partnerGuru/clientList/CreateClientList';
import ClientListEditPage from '../pages/partnerGuru/clientList/EditClientList';
import RecoverPasswordPage from '../pages/recoverPassword';
import ChangePasswordPage from '../pages/changePassword';
import ProfilePage from '../pages/profile';

const AppLayout = () => (
  <>
    <Menu />
    <Outlet />
    <Footer />
  </>
);

const AppLayoutPainel = () => (
  <>
    <Menu />
    <Sidebar />
    <Outlet />
    <Footer />
  </>
);

export default function DefaultRoutes() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path='/login' element={<LoginPage />} />
          <Route path='/signup' element={<SignUpPage />} />
          <Route path='/recoverPassword' element={<RecoverPasswordPage />} />
          <Route element={<AppLayout />}>
            <Route path='/' element={<HomePage />} />
            <Route path='/blog' element={<BlogPage />} />
            <Route path='/blog/post/:id' element={<PostBlogPage />} />
          </Route>
          <Route element={<AppLayoutPainel />}>
            <Route path='/meuPerfil' element={<ProtectedRoute roles={[Roles.CLIENTE_GURU, Roles.PARCEIRO_A_GURU]}> <ProfilePage /> </ProtectedRoute>} />
            <Route path='/changePassword' element={<ProtectedRoute roles={[Roles.CLIENTE_GURU, Roles.PARCEIRO_A_GURU, Roles.BLOGGER_GURU]}> <ChangePasswordPage /> </ProtectedRoute>} />
            <Route path='/painel' element={<ProtectedRoute roles={[Roles.PARCEIRO_A_GURU, Roles.PARCEIRO_B_GURU, Roles.BLOGGER_GURU]}> <PainelPage /> </ProtectedRoute> } />
            <Route path='/bannersBlog' element={<ProtectedRoute roles={[Roles.BLOGGER_GURU]}> <BannersBlogPage /> </ProtectedRoute>} />
            <Route path='/categorias' element={<ProtectedRoute roles={[Roles.BLOGGER_GURU]}> <BlogCategoryPage /> </ProtectedRoute>} />
            <Route path='/autores' element={<ProtectedRoute roles={[Roles.BLOGGER_GURU]}> <BlogAuthorPage /> </ProtectedRoute>} />
            <Route path='/posts/' element={<ProtectedRoute roles={[Roles.BLOGGER_GURU]}> <BlogPostsPage /> </ProtectedRoute>} />
            <Route path='/posts/criar' element={<ProtectedRoute roles={[Roles.BLOGGER_GURU]}> <BlogCreatePosts /> </ProtectedRoute>} />
            <Route path='/posts/editar/:id' element={<ProtectedRoute roles={[Roles.BLOGGER_GURU]}> <BlogEditPosts /> </ProtectedRoute>} />
            <Route path='/newsLetter' element={<ProtectedRoute roles={[Roles.BLOGGER_GURU]}> <NewsLetterBlogPage /> </ProtectedRoute>} />
            <Route path='/parceiroGuru/criar/' element={<ProtectedRoute roles={[Roles.PARCEIRO_A_GURU, Roles.PARCEIRO_B_GURU]}> <CreateCompanyPage /> </ProtectedRoute>} />
            <Route path='/parceiroGuru/:partner_id' element={<ProtectedRoute roles={[Roles.PARCEIRO_A_GURU, Roles.PARCEIRO_B_GURU]}> <CompanyGuru /> </ProtectedRoute>} />
            <Route path='/horarioGuru/:partner_id' element={<ProtectedRoute roles={[Roles.PARCEIRO_A_GURU, Roles.PARCEIRO_B_GURU]}> <BussinessHourPage /> </ProtectedRoute>} />
            <Route path='/servicosGuru/:partner_id' element={<ProtectedRoute roles={[Roles.PARCEIRO_A_GURU, Roles.PARCEIRO_B_GURU]}> <ServicesGuruPage /> </ProtectedRoute>} />
            <Route path='/servicosGuru/:partner_id/criar' element={<ProtectedRoute roles={[Roles.PARCEIRO_A_GURU, Roles.PARCEIRO_B_GURU]}> <ServicesCreatePage /> </ProtectedRoute>} />
            <Route path='/servicosGuru/:partner_id/editar/:id' element={<ProtectedRoute roles={[Roles.PARCEIRO_A_GURU, Roles.PARCEIRO_B_GURU]}> <ServicesEditPage /> </ProtectedRoute>} />
            <Route path='/colaboradoresGuru/:partner_id' element={<ProtectedRoute roles={[Roles.PARCEIRO_A_GURU, Roles.PARCEIRO_B_GURU]}> <EmployeesGuruPage /> </ProtectedRoute>} />
            <Route path='/colaboradoresGuru/:partner_id/criar' element={<ProtectedRoute roles={[Roles.PARCEIRO_A_GURU, Roles.PARCEIRO_B_GURU]}> <EmployeesCreatePage /> </ProtectedRoute>} />
            <Route path='/colaboradoresGuru/:partner_id/editar/:id' element={<ProtectedRoute roles={[Roles.PARCEIRO_A_GURU, Roles.PARCEIRO_B_GURU]}> <EmployeesEditPage /> </ProtectedRoute>} />
            <Route path='/agendamentosGuru/:partner_id' element={<ProtectedRoute roles={[Roles.PARCEIRO_A_GURU, Roles.PARCEIRO_B_GURU]}> <SchedulesGuruPage /> </ProtectedRoute>} />
            <Route path='/agendamentosGuru/:partner_id/criar' element={<ProtectedRoute roles={[Roles.PARCEIRO_A_GURU, Roles.PARCEIRO_B_GURU]}> <SchedulesCreatePage /> </ProtectedRoute>} />
            <Route path='/clientesGuru/:partner_id' element={<ProtectedRoute roles={[Roles.PARCEIRO_A_GURU, Roles.PARCEIRO_B_GURU]}> <ClientListPage /> </ProtectedRoute>} />
            <Route path='/clientesGuru/:partner_id/criar/' element={<ProtectedRoute roles={[Roles.PARCEIRO_A_GURU, Roles.PARCEIRO_B_GURU]}> <ClientListCreatePage /> </ProtectedRoute>} />
            <Route path='/clientesGuru/:partner_id/editar/:id/' element={<ProtectedRoute roles={[Roles.PARCEIRO_A_GURU, Roles.PARCEIRO_B_GURU]}> <ClientListEditPage /> </ProtectedRoute>} />
          </Route>
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  )
}

import React from 'react';
import styles from "./style.module.scss"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SectionBussinessHour from '../../../sections/sectionBussinessHour';

export default function BussinessHourPage() {
  return (
    <HelmetProvider>
      <section id={styles.BussinessHourPage}>
        <Helmet>
          <title>Horário de Funcionamento - GoinGuru</title>
          <meta name="description" content="GoinGuru" />
        </Helmet>
        <SectionBussinessHour />
      </section>
    </HelmetProvider>
  );
}

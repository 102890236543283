import React from 'react';
import styles from "./style.module.scss"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SectionEmployeesGuru from '../../../sections/sectionEmployeesGuru';

export default function EmployeesGuruPage() {
  return (
    <HelmetProvider>
      <section id={styles.EmployeesGuruPage}>
        <Helmet>
          <title>Meus Colaboradores - GoinGuru</title>
          <meta name="description" content="GoinGuru" />
        </Helmet>
        <SectionEmployeesGuru />
      </section>
    </HelmetProvider>
  );
}

import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, ListGroup, Form, FloatingLabel, Button, Modal, Spinner } from 'react-bootstrap';
import styles from './style.module.scss';
import { BlogAPI } from '../../api/blog';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Pagination from '../../components/pagination';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useAuthContext } from '../../contexts/authContext';

export default function BlogCategoryPage() {
    const { token } = useAuthContext();
    const [categoryName, setCategoryName] = useState('');
    const [errors, setErrors] = useState({});
    const [categories, setCategories] = useState([]);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [editedCategoryName, setEditedCategoryName] = useState('');
    const [categoryToDelete, setCategoryToDelete] = useState(null);
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 10; 

    const validateForm = () => {
        const newErrors = {};
        if (!categoryName) newErrors.categoryName = 'O nome é obrigatório!';
        return newErrors;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            setIsLoadingButton(true);
            try {
                const response = await BlogAPI.blogCategory(categoryName, token);
    
                if (response.status === 200) {
                    setIsLoadingButton(false);
                    setCategoryName('');
                    getCategoriesData();
                } else {
                    setIsLoadingButton(false);
                    console.error('Erro ao criar Categoria:', response.data);
                }
            } catch (error) {
                setIsLoadingButton(false);
                console.error('Erro ao criar Categoria:', error.message);
            }
        } else {
            setErrors(formErrors);
        }
    };

    const handleDelete = async () => {
        try {
            const response = await BlogAPI.deleteCategory(categoryToDelete, token);
            if (response.status === 200) {
                getCategoriesData();
                handleDeleteModalClose();
            } else {
                console.error('Erro ao deletar Categoria:', response);
            }
        } catch (error) {
            console.error('Erro ao deletar Categoria:', error.message);
        }
    };

    const handleDeleteModalOpen = (categoryId) => {
        setCategoryToDelete(categoryId);
        setDeleteModalOpen(true);
    };

    const handleDeleteModalClose = () => {
        setCategoryToDelete(null);
        setDeleteModalOpen(false);
    };

    const handleEditModalOpen = (categoryId, categoryName) => {
        setIsLoadingButton(false);
        setSelectedCategoryId(categoryId);
        setEditedCategoryName(categoryName);
        setEditModalOpen(true);
    };

    const handleEditModalClose = () => {
        setSelectedCategoryId('');
        setEditedCategoryName('');
        setEditModalOpen(false);
    };

    const handleEditSubmit = async () => {
        setIsLoadingButton(true);
        try {
            const response = await BlogAPI.editCategory(selectedCategoryId, editedCategoryName, token);
            if (response.status === 200) {
                setIsLoadingButton(false);
                handleEditModalClose();
                getCategoriesData();
            } else {
                setIsLoadingButton(false);
                console.error('Erro ao editar Categoria:', response);
            }
        } catch (error) {
            setIsLoadingButton(false);
            console.error('Erro ao editar Categoria:', error.message);
        }
    };

    const getCategoriesData = useCallback(async (page = 1) => {
        const responseCategories = await BlogAPI.getCategories(token, page);
        if(responseCategories.status === 200) {
            setCategories(responseCategories.data.results);
            setTotalPages(Math.ceil(responseCategories.data.count / itemsPerPage));
        }
    }, [token]);

    const handlePageChange = (page) => {
        getCategoriesData(page);
    };

    useEffect(() => {
        getCategoriesData();
    }, [getCategoriesData]);

    return (
        <HelmetProvider>
            <section id={styles.BlogCategoryPage}>
                <Helmet>
                    <title>Categorias - Blog</title>
                </Helmet>
                <Container>
                    <Row>
                        <p className={styles.title}>Criar Categoria</p>
                    </Row>
                    <Row>
                        <Col lg={4} md={6} sm={6}>
                            <Form className={styles.form}>
                                <FloatingLabel
                                    controlId="floatingCategoryName"
                                    label="Nome"
                                >
                                    <Form.Control
                                        className={styles.formLabel}
                                        type="text"
                                        value={categoryName}
                                        onChange={(e) => setCategoryName(e.target.value)}
                                        isInvalid={!!errors.categoryName}
                                    />
                                    <Form.Control.Feedback className={styles.formControl} type="invalid">
                                        {errors.categoryName}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Form>
                        </Col>
                        <Col lg={6} md={4} sm={4}>
                            <Button className={styles.button}  onClick={handleSubmit} disabled={isLoadingButton}>
                                {isLoadingButton ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Salvar"}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <ListGroup className={styles.listGroup}>
                            {categories && categories.length > 0 &&
                                categories.map((category) => (
                                    <ListGroup.Item key={category.id}>
                                        <Row>
                                            <Col lg={10} md={10} sm={10}>
                                                <p className={styles.text}>{category.name}</p>
                                            </Col>
                                            <Col lg={2} md={2} sm={2} className={styles.rowIcon}>
                                                <FaEdit className={styles.iconEdit} onClick={() => handleEditModalOpen(category.id, category.name)}/>
                                                <FaTrash className={styles.iconDelete} onClick={() => handleDeleteModalOpen(category.id)}/>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                ))
                            }
                        </ListGroup>
                    </Row>
                    <Row>
                        <Pagination totalPages={totalPages} onPageChange={handlePageChange}/>
                    </Row>
                </Container>

                {/* Modal Edit */}
                <Modal show={editModalOpen} onHide={handleEditModalClose} centered className={styles.modal}>
                    <Modal.Header closeButton>
                        <Modal.Title className={styles.title}>Editar Categoria</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className={styles.form}>
                            <Form.Group controlId="editCategoryName">
                                <Form.Control
                                    className={styles.formLabel}
                                    type="text"
                                    value={editedCategoryName}
                                    onChange={(e) => setEditedCategoryName(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className={styles.buttonCancel} onClick={handleEditModalClose}>
                            Cancelar
                        </Button>
                        <Button className={styles.buttonSave}  onClick={handleEditSubmit} disabled={isLoadingButton}>
                            {isLoadingButton ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Salvar"}
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Modal Delete */}
                <Modal show={deleteModalOpen} onHide={handleDeleteModalClose} centered className={styles.modal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar Exclusão</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Tem certeza de que deseja excluir esta categoria?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className={styles.buttonCancel} onClick={handleDeleteModalClose}>
                            Cancelar
                        </Button>
                        <Button className={styles.buttonSave} onClick={handleDelete}>
                            Excluir
                        </Button>
                    </Modal.Footer>
                </Modal>
            </section>
        </HelmetProvider>
    );
}

import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import styles from "./style.module.scss";
import { format, addDays, subDays, isSameDay, startOfDay } from 'date-fns';

export default function DatePagination({ startDate, onDateChange }) {
  const [currentDate, setCurrentDate] = useState(startOfDay(startDate));

  useEffect(() => {
    setCurrentDate(startOfDay(startDate));
  }, [startDate]);

  const handleDateChange = (date) => {
    setCurrentDate(startOfDay(date));
    onDateChange(startOfDay(date));
  };

  const handlePrevious = () => {
    const previousDate = new Date(currentDate);
    previousDate.setDate(previousDate.getDate() - 1);
    setCurrentDate(startOfDay(previousDate));
    onDateChange(startOfDay(previousDate));
  };

  const handleNext = () => {
    const nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + 1);
    setCurrentDate(startOfDay(nextDate));
    onDateChange(startOfDay(nextDate));
  };

  const [daysToAdd, setDaysToAdd] = useState(6);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 991.98) {
        setDaysToAdd(2);
      } else if(windowWidth >= 991.98) {
        setDaysToAdd(6);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [daysToAdd]);

  const renderDateButtons = () => {
    const buttons = [];
    const startDate = subDays(currentDate, daysToAdd/2);
    const endDate = addDays(currentDate, daysToAdd/2);
  
    let date = startDate;
    while (date <= endDate) {
      const buttonDate = new Date(date);
      buttons.push(
        <Button
          key={buttonDate.toISOString()}
          className={isSameDay(date, currentDate) ? styles.buttonPress : styles.button}
          onClick={() => handleDateChange(buttonDate)}
        >
          {isSameDay(date, currentDate)? (<div>
            {format(date, 'EE').toUpperCase()}
            </div>) :
            <div>
              {format(date, 'EE').slice(0, 3).toUpperCase()}
            </div>
          }
          {format(date, 'dd/MM')}
        </Button>
      );
      date = addDays(date, 1);
    }
  
    return buttons;
  };

  return (
    <section id={styles.DatePagination}>
      <div className={styles.container}>
        <div className={styles.rowButton}>
          <Button onClick={handlePrevious} className={styles.button}>
            <BiChevronLeft />
          </Button>
          {renderDateButtons()}
          <Button onClick={handleNext} className={styles.button}>
            <BiChevronRight />
          </Button>
        </div>
      </div>
    </section>
  );
}

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Image, FloatingLabel, Spinner } from 'react-bootstrap';
import styles from "./style.module.scss";
import logoGoinguru from "../../assets/logo.png";
import { LoginAPI } from '../../api/login';
import { useAuthContext } from '../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from 'react-toastify';

export default function SectionLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const { setToken } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    setShowRecaptcha(email !== '' && password !== '');
  }, [email, password]);

  const handleHome = () => {
    navigate('/');
  };

  const handleSignUp = () => {
    navigate('/signup');
  };

  const handlePassword = () => {
    navigate('/recoverPassword');
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    if (!captchaValue) {
      console.error('Por favor, resolva o reCAPTCHA.');
      return;
    }
    setIsLoadingButton(true);

    try {
      const response = await LoginAPI.login(email, password);

      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        await setToken(response.data.token);
        toast.success('Login realizado com sucesso!');
        setIsLoadingButton(false);
        navigate('/');
      } else if (response.status === 400) {
        toast.error('Erro ao fazer login, verifique o email e senha!');
        setIsLoadingButton(false);
      } else if (response.status === 404) {
        toast.error('Erro ao fazer login, verifique o email e senha!');
        setIsLoadingButton(false);
      }
    } catch (error) {
      console.error('Erro ao fazer login:', error.message);
      toast.error('Erro ao fazer login, verifique o email e senha!');
      setIsLoadingButton(false);
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <section id={styles.SectionLogin}>
      <Container className={styles.containerLogin}>
        <Row className="justify-content-center">
          <Col xs={12} md={6}>
            <Row className={styles.rowLogo}>
              <Image src={logoGoinguru} className={styles.logoLogin} onClick={handleHome} />
            </Row>
            <Row className={styles.rowTitle}>
              <p>Login</p>
            </Row>
            <Row className={styles.rowForm}>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <FloatingLabel controlId="email" label="Email">
                  <Form.Control
                    type="email"
                    placeholder="Digite seu email"
                    className={styles.formLabel}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">Por favor, insira um email válido.</Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel controlId="password" label="Senha">
                  <Form.Control
                    type="password"
                    placeholder="Digite sua senha"
                    className={styles.formLabel}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">Por favor, insira uma senha.</Form.Control.Feedback>
                </FloatingLabel>
                {showRecaptcha && (
                  <Row className={styles.rowRecaptcha}>
                    <ReCAPTCHA
                      sitekey="6LeaPe0pAAAAAG44B5AzphXhmL8IKsmJ9QUTjTsF"
                      onChange={handleCaptchaChange}
                      className={styles.recaptcha}
                    />
                  </Row>
                )}
                <Button className={styles.button} type="submit" disabled={isLoadingButton}>
                    {isLoadingButton ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Entrar"}
                </Button>
              </Form>
            </Row>
            <Row className={styles.rowSignUp}>
              <p className={styles.signUpText}>Ainda não tem conta? <strong onClick={handleSignUp}>Faça seu cadastro</strong></p>
              <p className={styles.signUpText}><strong onClick={handlePassword}>Esqueci a senha</strong></p>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

// NotFoundPage.js
import React from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styles from "./style.module.scss"
import logo from "../../assets/logo.png"
import { Helmet, HelmetProvider } from 'react-helmet-async';

const NotFoundPage = () => {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <HelmetProvider>
            <section id={styles.NotFoundPage}>
                <Helmet>
                    <title>Not Found - GoinGuru</title>
                </Helmet>
                <Container className={styles.container}>
                    <Row className={styles.rowLogo}>
                        <Image className={styles.logo} src={logo} onClick={handleGoHome}/>
                    </Row>
                    <Row>
                        <Col>
                            <p className={styles.title}>404</p>
                            <p className={styles.subTitle}>Página não encontrada</p>
                            <p className={styles.text}>A página que você está procurando não existe ou foi movida.</p>
                        </Col>
                    </Row>
                    <Row className={styles.rowButton}>
                        <Button className={styles.button} onClick={handleGoHome}>
                            Voltar para a página inicial
                        </Button>
                    </Row>
                </Container>
            </section>
        </HelmetProvider>
    );
};

export default NotFoundPage;

import React from 'react';
import styles from "./style.module.scss"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SectionClientListGuru from '../../../sections/sectionClientListGuru';

export default function ClientListPage() {
  return (
    <HelmetProvider>
      <section id={styles.HomePage}>
        <Helmet>
          <title>Lista de CLientes - GoinGuru</title>
          <meta name="description" content="GoinGuru" />
        </Helmet>
        <SectionClientListGuru />
      </section>
    </HelmetProvider>
  );
}

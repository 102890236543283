import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Image, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './style.module.scss';
import { BlogAPI } from '../../../api/blog';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useAuthContext } from '../../../contexts/authContext';

export default function BlogCreatePosts() {
    const { token } = useAuthContext();
    const navigate = useNavigate();
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [title, setTitle] = useState('');
    const [resume, setResume] = useState('');
    const [bodyPost, setBodyPost] = useState('');
    const [banner, setBanner] = useState(null);
    const [authors, setAuthors] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isPublished, setIsPublished] = useState(false);
    const [allCategories, setAllCategories] = useState([]);
    const [allAuthors, setAllAuthors] = useState([]);
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        
        if (!title) newErrors.title = 'O título é obrigatório';
        if (!resume) newErrors.resume = 'O resumo é obrigatório';
        if (!bodyPost) newErrors.bodyPost = 'O texto é obrigatório';
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }
        setIsLoadingButton(true);

        const formData = new FormData();
        formData.append('title', title);
        formData.append('resume', resume);
        formData.append('bodyPost', bodyPost);
        if (banner && banner instanceof File) {
            formData.append('banner', banner);
        }
        authors.forEach(author => formData.append('authors', author));
        categories.forEach(category => formData.append('categories', category));
        formData.append('is_published', isPublished);
        
        try {
            const response = await BlogAPI.blogPosts(formData, token);
            if (response.status === 200) {
                setTitle('');
                setResume('');
                setBodyPost('');
                setBanner(null);
                setAuthors([]);
                setCategories([]);
                setIsLoadingButton(false);
                navigate('/posts/');
            } else {
                setIsLoadingButton(false);
                console.error('Erro ao criar post:', response);
            }
        } catch (error) {
            setIsLoadingButton(false);
            console.error('Erro ao criar post:', error.message);
        }
    };

    const getCategoriesData = async () => {
        const responseCategories = await BlogAPI.getListCategories();
        if(responseCategories.status === 200) {
            setAllCategories(responseCategories.data);
        }
    }

    const getAuthorsData = async () => {
        const responseAuthors = await BlogAPI.getListAuhtor();
        if(responseAuthors.status === 200) {
            setAllAuthors(responseAuthors.data);
        }
    }

    useEffect(() => {
        getCategoriesData();
        getAuthorsData();
    }, []);

    const handleBack = () => {
        navigate('/posts/');
    };

    return (
        <HelmetProvider>
            <section id={styles.BlogPostsPage}>
                <Helmet>
                    <title>Criar Post - Blog</title>
                </Helmet>
                <Container>
                    <Row>
                        <p className={styles.title}>Criar Post</p>
                    </Row>
                    <Row className={styles.rowPost}>
                        <Form onSubmit={handleSubmit} className={styles.form}>
                            <Form.Group controlId="title">
                                <Row>
                                    <Col xs={12} md={6} className={styles.rowBanner}>
                                        {banner && (
                                            <Image
                                                src={banner instanceof Blob ? URL.createObjectURL(banner) : banner}
                                                className={styles.banner}
                                            />
                                        )}
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group controlId="banner">
                                            <Form.Label className={styles.formTitle}>Banner - (1280x720px)</Form.Label>
                                            <Form.Control
                                                className={styles.formControl}
                                                type="file"
                                                onChange={(e) => setBanner(e.target.files[0])}
                                                accept=".jpg,.jpeg,.png,.webp"
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Label className={styles.formTitle}>Título</Form.Label>
                                <Form.Control
                                    className={styles.formControl}
                                    type="text"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    maxLength={255}
                                    isInvalid={!!errors.title}
                                />
                                <Form.Control.Feedback className={styles.formControl} type="invalid">
                                    {errors.title}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="resume">
                                <Form.Label className={styles.formTitle}>Resumo</Form.Label>
                                <Form.Control
                                    className={styles.formControl}
                                    as="textarea"
                                    value={resume}
                                    onChange={(e) => setResume(e.target.value)}
                                    maxLength={255}
                                    isInvalid={!!errors.resume}
                                />
                                <Form.Control.Feedback className={styles.formControl} type="invalid">
                                    {errors.resume}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="body">
                                <Form.Label className={styles.formTitle}>Texto</Form.Label>
                                <ReactQuill
                                    value={bodyPost}
                                    onChange={setBodyPost}
                                    modules={{
                                        toolbar: [
                                            [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                                            [{size: []}],
                                            [{ 'align': [] }, 'bold', 'italic', 'underline', 'strike', 'blockquote',],
                                            [{'list': 'ordered'}, {'list': 'bullet'}, 
                                            {'indent': '-1'}, {'indent': '+1'}],
                                            ['link', 'image', 'video'],
                                            ['clean']
                                        ],
                                    }}
                                    formats={[
                                        'header', 'font', 'size',
                                        'align', 'bold', 'italic', 'underline', 'strike', 'blockquote',
                                        'list', 'bullet', 'indent',
                                        'link', 'image', 'video'
                                    ]}
                                    isInvalid={!!errors.bodyPost}
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.bodyPost}
                                </div>
                            </Form.Group>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group controlId="authors">
                                        <Form.Label className={styles.formTitle}>Autores</Form.Label>
                                        <Form.Control
                                            className={styles.formControl}
                                            as="select"
                                            multiple
                                            value={authors}
                                            onChange={(e) => setAuthors(Array.from(e.target.selectedOptions, (option) => Number(option.value)))}
                                        >
                                            {allAuthors && allAuthors.length > 0 &&
                                            allAuthors.map((author) => (
                                                <option key={author.id} value={author.id}>
                                                    {author.name}
                                                </option>
                                            ))
                                        }  
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group controlId="categories">
                                        <Form.Label className={styles.formTitle}>Categorias</Form.Label>
                                        <Form.Control
                                            className={styles.formControl}
                                            as="select"
                                            multiple
                                            value={categories}
                                            onChange={(e) => setCategories(Array.from(e.target.selectedOptions, (option) => Number(option.value)))}
                                        >
                                        {allCategories && allCategories.length > 0 &&
                                            allCategories.map((category) => (
                                                <option key={category.id} value={category.id}>
                                                    {category.name}
                                                </option>
                                            ))
                                        }    
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group controlId="isPublished">
                                <Form.Label className={styles.formTitle}>Publicar</Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="isPublishedSwitch"
                                    label={isPublished ? "Finalizado" : "Em andamento"}
                                    checked={isPublished}
                                    onChange={(e) => setIsPublished(e.target.checked)}
                                />
                            </Form.Group>
                            <Row className={styles.rowButton}>
                                <Button className={styles.buttonCancelar} onClick={handleBack}>Cancelar</Button>
                                <Button className={styles.button} type="submit" disabled={isLoadingButton}>
                                    {isLoadingButton ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Salvar"}
                                </Button>
                            </Row>
                        </Form>
                    </Row>
                </Container>
            </section>
        </HelmetProvider>
    );
}

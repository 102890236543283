import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { BASE_URL, AUTH_DEBUG, HttpResponse, HttpStatus, } from "../default";

const getPartnerResume = async (user_id, token) => {
    const url = `${BASE_URL}/partner/info/${user_id}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getPartnerResume(): ", data);
        return data;
    } else {
        throw new Error("Error on getPartnerResume()");
    }
};

export function usePartnerResumeData(user_id, token) {
    const query = useQuery({
        queryKey: ['PartnerResume', user_id],
        queryFn: () => getPartnerResume(user_id, token),
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10,
        enabled: !!token,
    });

    return {
        ...query,
        data: query.data,
    };
}

const postCreateOnePartner = async ({ formData, token }) => {
    const url = `${BASE_URL}/partner/partner/`;

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        let errorMessage = 'Erro na rede, tente novamente!';
        try {
            const errorData = await response.json();
            if (errorData && errorData.detail) {
                errorMessage = errorData.detail;
            } else if (errorData && typeof errorData === 'object') {
                errorMessage = Object.values(errorData).flat().join(', ');
            }
        } catch (e) {
        }
        throw new Error(errorMessage);
    }
    return response.json();
};

export function usePostCreateOnePartner(token) {
    const queryClient = useQueryClient(); 

    const mutate = useMutation({
        mutationFn: postCreateOnePartner,
        mutationKey: { token },
        enabled: [!!token],
        onError: (error) => {
            return error.message;
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['PartnerResume', token]);
        },
    });

    return mutate;
}

const postOnePartner = async ({ formData, partner_id, token }) => {
    const url = `${BASE_URL}/partner/partner/${partner_id}/`;

    const options = {
        method: 'PATCH',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        let errorMessage = 'Erro na rede, tente novamente!';
        try {
            const errorData = await response.json();
            if (errorData && errorData.detail) {
                errorMessage = errorData.detail;
            } else if (errorData && typeof errorData === 'object') {
                errorMessage = Object.values(errorData).flat().join(', ');
            }
        } catch (e) {
        }
        throw new Error(errorMessage);
    }
    return response.json();
};


export function usePostOnePartner(id) {
    const queryClient = useQueryClient(); 

    const mutate = useMutation({
        mutationFn: postOnePartner,
        mutationKey: { id },
        enabled: [!!id],
        onError: (error) => {
            return error.message;
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['OnePartner', id]);
        },
    });

    return mutate;
}

const getOnePartner = async (partner_id, token) => {
    const url = `${BASE_URL}/partner/partner/${partner_id}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getOnePartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getOnePartner()");
    }
};

export function useOnePartnerData(partner_id, token) {
    const query = useQuery({
        queryKey: ['OnePartner', partner_id],
        queryFn: () => getOnePartner(partner_id, token),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        enabled: !!token,
    });

    return {
        ...query,
        data: query.data,
    };
}

const getCategoriesAndServicesPartner = async (token) => {
    const url = `${BASE_URL}/partner/categoryAndService/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getCategoriesAndServicesPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getCategoriesAndServicesPartner()");
    }
};

export function useCategoriesAndServicesPartnerData(token) {
    const query = useQuery({
        queryKey: ['CategoriesAndServicesPartner'],
        queryFn: () => getCategoriesAndServicesPartner(token),
        staleTime: 1000 * 60 * 1,
        cacheTime: 1000 * 60 * 1,
        enabled: !!token,
    });

    return {
        ...query,
        categoriesData: query.data,
    };
}

const getOneCategoriesServicesPartner = async (partner_id, token) => {
    const url = `${BASE_URL}/partner/categoryAndService/${partner_id}/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getOneCategoriesServicesPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getOneCategoriesServicesPartner()");
    }
};

export function useOneCategoriesServicesPartnerData(partner_id, token) {
    const query = useQuery({
        queryKey: ['OneCategoriesServicesPartner'],
        queryFn: () => getOneCategoriesServicesPartner(partner_id, token),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        enabled: !!token,
    });

    return {
        ...query,
        categoriesData: query.data,
    };
}

//--------------------- SERVICES PARTNER ---------------------

const getServicesProvidedPartner = async (partner_id, token, page) => {
    const url = `${BASE_URL}/partner/servicesProvided/all/${partner_id}/?page=${page}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getServicesProvidedPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getServicesProvidedPartner()");
    }
};

export function useServicesProvidedData(partner_id, token, page) {
    const query = useQuery({
        queryKey: ['ServicesProvidedPartner', partner_id, page],
        queryFn: () => getServicesProvidedPartner(partner_id, token, page),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        enabled: !!token,
    });

    return {
        ...query,
        data: query.data,
    };
}

const getOneServicesProvided = async (service_id, token) => {
    const url = `${BASE_URL}/partner/servicesProvided/${service_id}/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getOneServicesProvided(): ", data);
        return data;
    } else {
        throw new Error("Error on getOneServicesProvided()");
    }
};

export function useOneServicesProvidedData(service_id, token) {
    const query = useQuery({
        queryKey: ['OneServicesProvidedPartner', service_id],
        queryFn: () => getOneServicesProvided(service_id, token),
        staleTime: 1000 * 60 * 30,
        cacheTime: 1000 * 60 * 30,
        enabled: !!token,
    });

    return {
        ...query,
        data: query.data,
    };
}

const postOneServicesProvided = async ({ formData, token }) => {
    const url = `${BASE_URL}/partner/servicesProvided/`;

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

export function usePostOneServicesProvided(partner_id) {
    const queryClient = useQueryClient();

    const mutate = useMutation({
        mutationFn: postOneServicesProvided,
        mutationKey: { partner_id },
        enabled: [!!partner_id],
        onSuccess: () => {
            queryClient.invalidateQueries(['ServicesProvidedPartner', partner_id]);
        },
    });

    return mutate;
}

const patchOneServicesProvided = async ({ formData, token, service_id }) => {
    const url = `${BASE_URL}/partner/servicesProvided/${service_id}/`;

    const options = {
        method: 'PATCH',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

export function usePatchOneServicesProvided(partner_id, service_id) {
    const queryClient = useQueryClient();

    const mutate = useMutation({
        mutationFn: patchOneServicesProvided,
        mutationKey: { partner_id },
        enabled: [!!partner_id],
        onSuccess: () => {
            queryClient.invalidateQueries(['ServicesProvidedPartner', 'OneServicesProvidedPartner', partner_id, service_id]);
        },
    });

    return mutate;
}

const deleteOneServicesProvided = async ( serviceToDelete, token) => {
    const url = `${BASE_URL}/partner/servicesProvided/${serviceToDelete}/`
    try {
        const options = {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                jwt: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
        }

        const response = await fetch(url, options);
        if (response.ok) {
            return new HttpResponse(HttpStatus.OK, null);
        } else {
            throw new Error("Error on deleteOneServicesProvided()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, null);
    }
}
//--------------------- Employee PARTNER ---------------------

const getEmployeePartner = async (partner_id, token, page) => {
    const url = `${BASE_URL}/partner/employeePartner/all/${partner_id}/?page=${page}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getEmployeePartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getEmployeePartner()");
    }
};

export function useEmployeePartnerData(partner_id, token, page) {
    const query = useQuery({
        queryKey: ['EmployeePartner', partner_id, page],
        queryFn: () => getEmployeePartner(partner_id, token, page),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        enabled: !!token,
    });

    return {
        ...query,
        data: query.data,
    };
}

const getResumeServicesProvidedPartner = async (partner_id, token) => {
    const url = `${BASE_URL}/partner/servicesProvided/resume/${partner_id}/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getResumeServicesProvidedPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getResumeServicesProvidedPartner()");
    }
};

export function useResumeServicesProvidedData(partner_id, token) {
    const query = useQuery({
        queryKey: ['ResumeServicesProvidedPartner', partner_id],
        queryFn: () => getResumeServicesProvidedPartner(partner_id, token),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        enabled: !!token,
    });

    return {
        ...query,
        servicesData: query.data,
    };
}

const postOneEmployeePartner = async ({ formData, token }) => {
    const url = `${BASE_URL}/partner/employeePartner/`;

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

export function usePostOneEmployeePartner(partner_id) {
    const queryClient = useQueryClient();

    const mutate = useMutation({
        mutationFn: postOneEmployeePartner,
        mutationKey: { partner_id },
        enabled: [!!partner_id],
        onSuccess: () => {
            queryClient.invalidateQueries(['EmployeePartner', partner_id]);
        },
    });

    return mutate;
}

const getOneEmployeePartner = async (employee_id, token) => {
    const url = `${BASE_URL}/partner/employeePartner/${employee_id}/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getOneServicesProvided(): ", data);
        return data;
    } else {
        throw new Error("Error on getOneServicesProvided()");
    }
};

export function useOneEmployeePartnerData(employee_id, token) {
    const query = useQuery({
        queryKey: ['OneEmployeePartner', employee_id],
        queryFn: () => getOneEmployeePartner(employee_id, token),
        staleTime: 1000 * 60 * 30,
        cacheTime: 1000 * 60 * 30,
        enabled: !!token,
    });

    return {
        ...query,
        data: query.data,
    };
}

const patchOneEmployeePartner = async ({ formData, token, employee_id }) => {
    const url = `${BASE_URL}/partner/employeePartner/${employee_id}/`;

    const options = {
        method: 'PATCH',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

export function usePatchOneEmployeePartner(partner_id) {
    const queryClient = useQueryClient();

    const mutate = useMutation({
        mutationFn: patchOneEmployeePartner,
        mutationKey: { partner_id },
        enabled: [!!partner_id],
        onSuccess: () => {
            queryClient.invalidateQueries(['EmployeePartner', partner_id]);
        },
    });

    return mutate;
}

const deleteOneEmployee = async ( employeeToDelete, token) => {
    const url = `${BASE_URL}/partner/employeePartner/${employeeToDelete}/`
    try {
        const options = {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                jwt: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
        }

        const response = await fetch(url, options);
        if (response.ok) {
            return new HttpResponse(HttpStatus.OK, null);
        } else {
            throw new Error("Error on deleteOneServicesProvided()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, null);
    }
}

//--------------------- SCHEDULES PARTNER ---------------------

const getEmployeeListPartner = async (partner_id, token) => {
    const url = `${BASE_URL}/partner/employeePartner/allList/${partner_id}/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getEmployeeListPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getEmployeeListPartner()");
    }
};

export function useEmployeeListPartnerData(partner_id, token) {
    const query = useQuery({
        queryKey: ['EmployeePartnerList', partner_id],
        queryFn: () => getEmployeeListPartner(partner_id, token),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        enabled: !!token,
    });

    return {
        ...query,
        data: query.data,
    };
}

const getDaysSchedulesOneEmployeePartner = async (partner_id, start_date, end_date, employee_id, token) => {
    const url = `${BASE_URL}/partner/schedulesList/partner/${partner_id}/?start_date=${start_date}&end_date=${end_date}&employee_id=${employee_id}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getDaysSchedulesOneEmployeePartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getDaysSchedulesOneEmployeePartner()");
    }
};

export function useDaysSchedulesOneEmployeePartnerData(partner_id, start_date, end_date, employee_id, token, shouldFetchSchedules) {
    const queryEnabled = !!token && !!start_date && shouldFetchSchedules;

    const query = useQuery({
        queryKey: ['DaysSchedulesPartner', employee_id],
        queryFn: () => {
            if (queryEnabled) {
                return getDaysSchedulesOneEmployeePartner(partner_id, start_date, end_date, employee_id, token);
            } else {
                return Promise.resolve([]);
            }
        },
        enabled: queryEnabled,
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        retry: 0,
    });

    return {
        ...query,
        data: query.data,
    };
}

const getDaysSchedulesPartner = async (partner_id, service_id, start_date, end_date, employee_id, token) => {
    const url = `${BASE_URL}/partner/schedules/partner/${partner_id}/service/${service_id}/?start_date=${start_date}&end_date=${end_date}&employee_id=${employee_id}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getDaysSchedulesPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getDaysSchedulesPartner()");
    }
};

export function useDaysSchedulesPartnerData(partner_id, service_id, start_date, end_date, employee_id, token, shouldFetchServices) {
    const queryEnabled = !!token && !!start_date && !!service_id && shouldFetchServices;

    const query = useQuery({
        queryKey: ['DaysSchedulesPartner', employee_id],
        queryFn: () => {
            if (queryEnabled) {
                return getDaysSchedulesPartner(partner_id, service_id, start_date, end_date, employee_id, token);
            } else {
                return Promise.resolve([]);
            }
        },
        enabled: queryEnabled,
        staleTime: 1000 * 60 * 30,
        cacheTime: 1000 * 60 * 30,
        retry: 0,
    });

    return {
        ...query,
        data: query.data,
    };
}

const getClientPartner = async (partner_id, clientEmail, token) => {
    const url = `${BASE_URL}/partner/clients/getClient/?email-client=${clientEmail}&partner_id=${partner_id}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getClientPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getClientPartner()");
    }
};

export function useClientPartnerData(partner_id, clientEmail, token, shouldFetch) {
    const query = useQuery({
        queryKey: [ partner_id, clientEmail],
        queryFn: () => getClientPartner(partner_id, clientEmail, token),
        enabled: shouldFetch && !!clientEmail,
        retry: 0,
    });

    return {
        ...query,
        ClientData: query.data,
    };
}

const postClientPartner = async ({ formData, partner_id, token }) => {
    const url = `${BASE_URL}/partner/clients/createClient/${partner_id}/`;

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

export function usePostClientPartner(partner_id, clientEmail) {

    const mutate = useMutation({
        mutationFn: postClientPartner,
        mutationKey: { partner_id },
        enabled: [!!partner_id && !!clientEmail],
    });

    return mutate;
}

const postScheduleEmployeePartner = async ({ formData, token }) => {
    const url = `${BASE_URL}/partner/schedules/`;

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

export function usePostScheduleEmployeePartner(partner_id, clientId) {
    const mutate = useMutation({
        mutationFn: postScheduleEmployeePartner,
        mutationKey: { partner_id },
        enabled: [!!partner_id && !!clientId],
    });

    return mutate;
}

//--------------------- CLIENT LIST ---------------------

const getClientListPartner = async (partner_id, token, page) => {
    const url = `${BASE_URL}/partner/clients/getClientList/${partner_id}/?page=${page}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getClientListPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getClientListPartner()");
    }
};

export function useClientListPartnerData(partner_id, token, page) {
    const query = useQuery({
        queryKey: ['ClientListPartner', partner_id, page],
        queryFn: () => getClientListPartner(partner_id, token, page),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        enabled: !!token,
    });

    return {
        ...query,
        data: query.data,
    };
}

const postOneClientListPartner = async ({ formData, partner_id, token }) => {
    const url = `${BASE_URL}/partner/clients/createClient/${partner_id}/`;

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

export function usePostOneClientListPartner(partner_id, clientEmail) {
    const queryClient = useQueryClient();

    const mutate = useMutation({
        mutationFn: postOneClientListPartner,
        mutationKey: { partner_id },
        enabled: [!!partner_id && !!clientEmail],
        onSuccess: () => {
            queryClient.invalidateQueries(['ClientListPartner', partner_id]);
        },
    });

    return mutate;
}

const getOneClientListPartner = async (client_id, token) => {
    const url = `${BASE_URL}/partner/clientList/${client_id}/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getOneClientListPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getOneClientListPartner()");
    }
};

export function useOneClientListPartnerData(client_id, token) {
    const query = useQuery({
        queryKey: ['ClientListPartner', client_id],
        queryFn: () => getOneClientListPartner(client_id, token),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        enabled: !!token,
    });

    return {
        ...query,
        data: query.data,
    };
}

const patchOneClientListPartner = async ({ formData, client_id, token }) => {
    const url = `${BASE_URL}/partner/clientList/${client_id}/`;

    const options = {
        method: 'PATCH',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    const responseData = await response.json();

    if (!response.ok) {
        const errorDetail = responseData?.cpf?.[0] || responseData.detail || 'Erro na requisição';
        throw new Error(errorDetail);
    }

    return responseData;
};

export function usePatchOneClientListPartner(partner_id, clientEmail) {
    const queryClient = useQueryClient();

    const mutate = useMutation({
        mutationFn: patchOneClientListPartner,
        mutationKey: { partner_id },
        enabled: [!!partner_id && !!clientEmail],
        onSuccess: () => {
            queryClient.invalidateQueries(['ClientListPartner', partner_id]);
        },
    });

    return mutate;
}

const deleteOneClient = async ( clientToDelete, token) => {
    const url = `${BASE_URL}/partner/clientList/${clientToDelete}/`
    try {
        const options = {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                jwt: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
        }

        const response = await fetch(url, options);
        if (response.ok) {
            return new HttpResponse(HttpStatus.OK, null);
        } else {
            throw new Error("Error on deleteOneServicesProvided()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, null);
    }
}

//--------------------- CLIENT LIST PARTNER ---------------------

export const PartnerAPI = {
    deleteOneServicesProvided,
    deleteOneEmployee,
    deleteOneClient,
}

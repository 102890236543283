import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Form, Button, Image, Card, Alert } from 'react-bootstrap';
import styles from './style.module.scss';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../../../contexts/authContext';
import { useCategoriesAndServicesPartnerData, usePostCreateOnePartner } from '../../../../api/PartnerAPI/partnerClient';
import { isValidCPF } from '../../../../utils/utils';

export default function CreateCompanyPage() {
    const navigate = useNavigate();
    const { token, user } = useAuthContext();
    const { categoriesData } = useCategoriesAndServicesPartnerData(token);
    const { mutate: postCreateOnePartner, isError, isSuccess, error } = usePostCreateOnePartner(token);

    const cpfMask = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    const cnpjMask = [/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    const phoneMask = ['+', '5', '5', ' ', '(', /[0-9]/, /\d/, ')', ' ', /[0-9]/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    const whatsMask = ['+', '5', '5', ' ', '(', /[0-9]/, /\d/, ')', ' ', /[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    const [formValues, setFormValues] = useState({
        banner: null,
        empresa: '',
        email: '',
        isCNPJ: false,
        cpf: '',
        cnpj: '',
        phone: '',
        whatsapp: '',
        categorias: [],
        tags: [],
        sobre: '',
        cep: '',
        state: '',
        city: '',
        neighborhood: '',
        street: '',
        number: '',
        complement: '',
    });
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        if (!formValues.empresa) newErrors.empresa = 'O nome da empresa é obrigatório!';
        if (!formValues.email) newErrors.email = 'O email é obrigatório!';
        if (!/\S+@\S+\.\S+/.test(formValues.email)) newErrors.email = 'O email é inválido!';
        if (formValues.isCNPJ && !formValues.cnpj) newErrors.cnpj = 'O CNPJ é obrigatório!';
        if (!formValues.isCNPJ && !formValues.cpf) {
            newErrors.cpf = "CPF é obrigatório.";
        } else if (!isValidCPF(formValues.cpf.replace(/\D/g, ''))) {
            newErrors.cpf = "CPF inválido.";
        }
        if (!formValues.phone) {
            newErrors.phone = "O telefone é obrigatório!";
        } else if (formValues.phone.replace(/\D/g, '').length < 10) {
            newErrors.phone = "O telefone deve ter pelo menos 10 dígitos.";
        }
        if (!formValues.whatsapp) {
            newErrors.whatsapp = "WhatsApp é obrigatório.";
        } else if (formValues.whatsapp.replace(/\D/g, '').length < 11) {
            newErrors.whatsapp = "WhatsApp deve ter pelo menos 11 dígitos.";
        }
        if (!formValues.categorias) newErrors.categorias = 'O campo categoria é obrigatório!';
        if (formValues.categorias.length === 0) newErrors.categorias = 'O campo categoria é obrigatório!';
        if (formValues.tags.length === 0) newErrors.tags = 'O campo tags é obrigatório!';
        if (!formValues.cep) newErrors.cep = 'O CEP é obrigatório!';
        if (!formValues.state) newErrors.state = 'O estado é obrigatório!';
        if (!formValues.city) newErrors.city = 'A cidade é obrigatória!';
        if (!formValues.neighborhood) newErrors.neighborhood = 'O bairro é obrigatório!';
        if (!formValues.street) newErrors.street = 'A rua é obrigatória!';
        if (!formValues.number) newErrors.number = 'O número é obrigatório!';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        
        if (!validateForm()) {
            return;
        }

        const formData = new FormData();
    
        formData.append('user', user.id);
        formData.append('photo', formValues.banner);
        formData.append('name', formValues.empresa);
        formData.append('email', formValues.email);
        formData.append('is_cnpj', formValues.isCNPJ);
        formData.append('cnpj', formValues.cnpj);
        formData.append('cpf', formValues.cpf);
        formData.append('phone', formValues.phone);
        formData.append('whatsapp', formValues.whatsapp);
        formValues.categorias.forEach(categoria => formData.append('categories_partners', categoria));
        formValues.tags.forEach(tag => formData.append('services_partners', tag));
        formData.append('about', formValues.sobre);
        formData.append('a_postal_code', formValues.cep);
        formData.append('a_state', formValues.state);
        formData.append('a_city', formValues.city);
        formData.append('a_neighborhood', formValues.neighborhood);
        formData.append('a_street_name', formValues.street);
        formData.append('a_number', formValues.number);
        formData.append('a_complement', formValues.complement);
        formData.append('is_published', true);

        if (Object.keys(formValues).length === 0) {
            return;
        }

        postCreateOnePartner({ formData, token });
    };
    
    useEffect(() => {
        if (isError && error) {
            const errorMessage = error.message.toLowerCase();
            if (errorMessage.includes('cpf')) {
                toast.error('CPF inválido ou já cadastrado.');
            } 
            if (errorMessage.includes('email')) {
                toast.error('Email inválido ou já cadastrado.');
            }
            if (errorMessage.includes('cnpj')) {
                toast.error('CNPJ inválido ou já cadastrado.');
            } 
            if (!errorMessage.includes('cpf') && 
                !errorMessage.includes('email') && 
                !errorMessage.includes('cnpj')) {
                toast.error(`Erro ao criar Perfil da Empresa, tente novamente ou entre em contato com o suporte!`);
            }
        } else if (isSuccess) {
            toast.success('Perfil da Empresa criado com sucesso!');
            navigate('/painel');
        }
    }, [isError, isSuccess, error, navigate]);
    
    const categoriesOptions = useMemo(() => 
        categoriesData?.categories?.map(categories => ({
            value: categories.id, 
            label: categories.name
        })) || [], 
        [categoriesData]
    );

    const tagsOptions = useMemo(() => 
        categoriesData?.services?.map(tags => ({
            value: tags.id, 
            label: tags.name
        })) || [], 
        [categoriesData]
    );

    const handleCategoryRemove = (id) => {
        setFormValues((prev) => ({
            ...prev,
            categorias: prev.categorias.filter(categoriaId => categoriaId !== id)
        }));
    };

    const handleTagRemove = (id) => {
        setFormValues((prev) => ({
            ...prev,
            tags: prev.tags.filter(tagId => tagId !== id)
        }));
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [id]: value
        }));
    };

    const handleCancel = () => {
        navigate('/painel')
    };

    return (
        <section id={styles.CreateCompanyPage}>
            <Container>
                <Row>
                    <Col xs={12} md={12}>
                        <p className={styles.title}>Criar Perfil da Empresa</p>
                    </Col>
                    
                </Row>
                <Row className={styles.rowForm}>
                    <Form onSubmit={handleSubmit} className={styles.form}>
                        <Row className={styles.rowBanner}>
                            <Col xs={12} md={6} className={styles.colBanner}>
                                {formValues.banner && (
                                    <Image
                                        src={formValues.banner instanceof Blob ? URL.createObjectURL(formValues.banner) : formValues.banner}
                                        className={styles.banner}
                                    />
                                )}
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="banner">
                                    <Form.Label className={styles.formTitle}>Banner</Form.Label>
                                    <Form.Control
                                        type="file"
                                        className={styles.formControl}
                                        onChange={(e) => setFormValues({ ...formValues, banner: e.target.files[0] })}
                                        accept=".jpg,.jpeg,.png,.webp"
                                        isInvalid={!!errors.banner}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.banner}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="empresa">
                                    <Form.Label className={styles.formTitle}>Nome da Empresa</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className={styles.formControl}
                                        value={formValues.empresa}
                                        onChange={(e) => setFormValues({ ...formValues, empresa: e.target.value })}
                                        isInvalid={!!errors.empresa}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.empresa}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="email">
                                    <Form.Label className={styles.formTitle}>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        className={styles.formControl}
                                        value={formValues.email}
                                        onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="isCNPJ">
                                    <Form.Label className={styles.formTitle}>Possui CNPJ?</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="isCNPJSwitch"
                                        label={formValues.isCNPJ ? "Sim" : "Não"}
                                        checked={formValues.isCNPJ}
                                        onChange={(e) => setFormValues({ ...formValues, isCNPJ: e.target.checked })}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                {!formValues.isCNPJ ? (
                                    <Form.Group controlId="cpf">
                                        <Form.Label className={styles.formTitle}>CPF</Form.Label>
                                        <MaskedInput
                                            mask={cpfMask}
                                            className={styles.formControl + " form-control"}
                                            value={formValues.cpf}
                                            onChange={(e) => setFormValues({ ...formValues, cpf: e.target.value })}
                                            render={(ref, props) => (
                                                <Form.Control
                                                    ref={ref}
                                                    {...props}
                                                    isInvalid={!!errors.cpf}
                                                />
                                            )}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.cpf}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                ) : (
                                    <Form.Group controlId="cnpj">
                                        <Form.Label className={styles.formTitle}>CNPJ</Form.Label>
                                        <MaskedInput
                                            mask={cnpjMask}
                                            className={styles.formControl + " form-control"}
                                            value={formValues.cnpj}
                                            onChange={(e) => setFormValues({ ...formValues, cnpj: e.target.value })}
                                            render={(ref, props) => (
                                                <Form.Control
                                                    ref={ref}
                                                    {...props}
                                                    isInvalid={!!errors.cnpj}
                                                />
                                            )}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.cnpj}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="phone">
                                    <Form.Label className={styles.formTitle}>Telefone</Form.Label>
                                    <MaskedInput
                                        mask={phoneMask}
                                        className={styles.formControl + " form-control"}
                                        value={formValues.phone}
                                        onChange={(e) => setFormValues({ ...formValues, phone: e.target.value })}
                                        render={(ref, props) => (
                                            <Form.Control
                                                ref={ref}
                                                {...props}
                                                isInvalid={!!errors.phone}
                                            />
                                        )}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.phone}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="whatsapp">
                                    <Form.Label className={styles.formTitle}>WhatsApp</Form.Label>
                                    <MaskedInput
                                        mask={whatsMask}
                                        className={styles.formControl + " form-control"}
                                        value={formValues.whatsapp}
                                        onChange={(e) => setFormValues({ ...formValues, whatsapp: e.target.value })}
                                        render={(ref, props) => (
                                            <Form.Control
                                                ref={ref}
                                                {...props}
                                                isInvalid={!!errors.whatsapp}
                                            />
                                        )}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.whatsapp}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="categorias">
                                    <Form.Label className={styles.formTitle}>Categorias</Form.Label>
                                    <Select 
                                        options={categoriesOptions}
                                        placeholder="Selecione as Categorias"
                                        isClearable
                                        styles={customStyles}
                                        onChange={(selectedOption) => {
                                            const selectedId = selectedOption ? selectedOption.value : null;
                                            if (selectedId && !formValues.categorias.includes(selectedId)) {
                                                if (formValues.categorias.length >= 10) {
                                                    toast.error('Você pode selecionar no máximo 10 categorias.');
                                                    return;
                                                }
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    categorias: [...prev.categorias, selectedId]
                                                }));
                                            }
                                        }}
                                    />
                                    <div className={styles.selectedDrop}>
                                        {formValues.categorias.map((categoriaId) => {
                                            const categoria = categoriesData?.categories.find(c => c.id === categoriaId);
                                            return (
                                                <Button
                                                    key={categoriaId}
                                                    onClick={() => handleCategoryRemove(categoriaId)}
                                                    className={styles.selectedDrop}
                                                >
                                                    {categoria ? categoria.name : "Categoria Desconhecida"} &times;
                                                </Button>
                                            );
                                        })}
                                    </div>
                                    {errors.categorias && (
                                        <Alert variant="danger">
                                            {errors.categorias}
                                        </Alert>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="tags">
                                    <Form.Label className={styles.formTitle}>Tags</Form.Label>
                                    <Select 
                                        options={tagsOptions}
                                        placeholder="Selecione as Tags"
                                        isClearable
                                        styles={customStyles}
                                        onChange={(selectedOption) => {
                                            const selectedId = selectedOption ? selectedOption.value : null;
                                            if (selectedId && !formValues.tags.includes(selectedId)) {
                                                if (formValues.tags.length >= 10) {
                                                    toast.error('Você pode selecionar no máximo 10 categorias.');
                                                    return;
                                                }
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    tags: [...prev.tags, selectedId]
                                                }));
                                            }
                                        }}
                                    />
                                    <div className={styles.selectedDrop}>
                                        {formValues.tags.map((tagId) => {
                                            const tag = categoriesData?.services.find(t => t.id === tagId);
                                            return (
                                                <Button
                                                    key={tagId}
                                                    onClick={() => handleTagRemove(tagId)}
                                                    className={styles.selectedDrop}
                                                >
                                                    {tag?.name} &times;
                                                </Button>
                                            );
                                        })}
                                    </div>
                                    {errors.tags && (
                                        <Alert variant="danger">
                                            {errors.tags}
                                        </Alert>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group controlId="sobre">
                            <Form.Label className={styles.formTitle}>Sobre</Form.Label>
                            <Form.Control
                                as="textarea"
                                className={styles.formControl}
                                value={formValues.sobre}
                                onChange={(e) => setFormValues({ ...formValues, sobre: e.target.value })}
                                maxLength={300}
                                isInvalid={!!errors.sobre}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.sobre}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Row className={styles.rowTitleAddress}>
                            <Col xs={12} md={12}>
                                <p className={styles.title}>Endereço</p>
                            </Col>
                        </Row>
                        <Row>
                            <Card className={styles.cardAddress}>
                                <Card.Body>
                                    <Form className={styles.form}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                <Form.Group controlId="cep">
                                                    <Form.Label className={styles.formTitle}>CEP</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Digite seu CEP"
                                                        className={styles.formControl}
                                                        value={formValues.cep}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.cep && <Form.Text className="text-danger">{errors.cep}</Form.Text>}
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                <Form.Group controlId="street">
                                                    <Form.Label className={styles.formTitle}>Rua/Avenida</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Digite sua rua ou avenida"
                                                        className={styles.formControl}
                                                        value={formValues.street}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.street && <Form.Text className="text-danger">{errors.street}</Form.Text>}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                <Form.Group controlId="number">
                                                    <Form.Label className={styles.formTitle}>Número</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Digite o número"
                                                        className={styles.formControl}
                                                        value={formValues.number}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.number && <Form.Text className="text-danger">{errors.number}</Form.Text>}
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                <Form.Group controlId="neighborhood">
                                                    <Form.Label className={styles.formTitle}>Bairro</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Digite seu bairro"
                                                        className={styles.formControl}
                                                        value={formValues.neighborhood}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.neighborhood && <Form.Text className="text-danger">{errors.neighborhood}</Form.Text>}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                <Form.Group controlId="state">
                                                    <Form.Label className={styles.formTitle}>Estado</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Digite seu estado"
                                                        className={styles.formControl}
                                                        value={formValues.state}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.state && <Form.Text className="text-danger">{errors.state}</Form.Text>}
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                <Form.Group controlId="city">
                                                    <Form.Label className={styles.formTitle}>Cidade</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Digite sua cidade"
                                                        className={styles.formControl}
                                                        value={formValues.city}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.city && <Form.Text className="text-danger">{errors.city}</Form.Text>}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="complement">
                                                    <Form.Label className={styles.formTitle}>Complemento</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Digite o complemento"
                                                        className={styles.formControl}
                                                        value={formValues.complement}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Row>
                        <Row className={styles.rowButton}>
                            <Col xs={6} md={6} className={styles.colButtonCancel}>
                                <Button className={styles.buttonCancel} onClick={handleCancel}>
                                    Cancelar
                                </Button>
                            </Col>
                            <Col xs={6} md={6} className={styles.colButtonSend}>
                                <Button className={styles.button} type="submit">
                                    Enviar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Row>
            </Container>
        </section>
    );
}

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        marginTop: '5px',
        borderColor: '#EE880C',
        boxShadow: state.isFocused ? '0 0 5px 2px #EE880C' : provided.boxShadow,
        '&:hover': {
            borderColor: '#EE880C'
        }
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        '&:hover': {
            color: '#EE880C'
        }
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#390040' : state.isFocused ? '#EE880C' : provided.backgroundColor,
        color: state.isSelected ? '#fff' : state.isFocused ? '#fff' : provided.color,
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#EE880C',
            color: '#fff'
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#390040',
    }),
};
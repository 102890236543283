import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap';
import styles from "./style.module.scss";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useOnePartnerData, usePostOnePartner } from '../../api/PartnerAPI/partnerClient';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../contexts/authContext';
import { toast } from 'react-toastify';

const daysOfWeek = [
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
    'Domingo'
];

const dayKeys = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
];

const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
            const formattedHour = hour.toString().padStart(2, '0');
            const formattedMinute = minute.toString().padStart(2, '0');
            times.push(`${formattedHour}:${formattedMinute}`);
        }
    }
    return times;
};

const timeOptions = generateTimeOptions();

const defaultWorkingHours = dayKeys.reduce((acc, day) => {
    acc[day] = {
        is_active: true,
        open_time: '09:00',
        close_time: '18:00',
        has_break: false,
        break_start_time: null,
        break_end_time: null
    };
    return acc;
}, {});

export default function SectionBussinessHour() {
    const { partner_id } = useParams();
    const { token } = useAuthContext();
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const { data, isLoading, isError } = useOnePartnerData(partner_id, token);
    const { mutate: postOnePartner } = usePostOnePartner(partner_id);
    const [isEditing, setIsEditing] = useState(false);
    const [openCloseState, setOpenCloseState] = useState(Array(daysOfWeek.length).fill(true));
    const [workingHours, setWorkingHours] = useState(defaultWorkingHours);

    useEffect(() => {
        if (data) {
            setWorkingHours(data.working_hours || defaultWorkingHours);
            setOpenCloseState(dayKeys.map(day => data.working_hours ? data.working_hours[day].is_active : true));
        }
    }, [data]);

    const handleCancel = () => {
        setIsEditing(false);
    };

    const handleOpenCloseChange = (index) => {
        const newOpenCloseState = [...openCloseState];
        const newWorkingHours = { ...workingHours };

        newOpenCloseState[index] = !newOpenCloseState[index];
        newWorkingHours[dayKeys[index]].is_active = newOpenCloseState[index];

        if (!newOpenCloseState[index]) {
            newWorkingHours[dayKeys[index]].open_time = null;
            newWorkingHours[dayKeys[index]].close_time = null;
            newWorkingHours[dayKeys[index]].has_break = false;
            newWorkingHours[dayKeys[index]].break_start_time = null;
            newWorkingHours[dayKeys[index]].break_end_time = null;
        }

        setOpenCloseState(newOpenCloseState);
        setWorkingHours(newWorkingHours);
    };

    const handleTimeChange = (index, field, value) => {
        const newWorkingHours = { ...workingHours };
    
        if (field === 'open_time') {
            if (value >= newWorkingHours[dayKeys[index]].close_time) {
                alert("O horário de abertura não pode ser maior ou igual ao horário de fechamento.");
                return;
            }
        } else if (field === 'close_time') {
            if (value <= newWorkingHours[dayKeys[index]].open_time) {
                alert("O horário de fechamento não pode ser menor ou igual ao horário de abertura.");
                return;
            }
        }

        newWorkingHours[dayKeys[index]][field] = value;
    
        if (field === 'break_start_time' || field === 'break_end_time') {
            const breakStart = newWorkingHours[dayKeys[index]].break_start_time;
            const breakEnd = newWorkingHours[dayKeys[index]].break_end_time;
    
            if (breakStart && breakEnd) {
                if (breakStart >= breakEnd) {
                    alert("O horário de início da pausa não pode ser maior ou igual ao horário de término da pausa.");
                    return;
                }
                if (breakStart < newWorkingHours[dayKeys[index]].open_time || breakEnd > newWorkingHours[dayKeys[index]].close_time) {
                    alert("Os horários de pausa devem estar dentro do horário de funcionamento.");
                    return;
                }
            }
        }
    
        setWorkingHours(newWorkingHours);
    };
    
    const handleBreakChange = (index, value) => {
        const newWorkingHours = { ...workingHours };
        newWorkingHours[dayKeys[index]].has_break = value;
    
        if (!value) {
            newWorkingHours[dayKeys[index]].break_start_time = null;
            newWorkingHours[dayKeys[index]].break_end_time = null;
        }
    
        setWorkingHours(newWorkingHours);
    };

    const handleSubmit = async () => {
        setIsLoadingButton(true);

        const updatedWorkingHours = { ...workingHours };
        dayKeys.forEach(day => {
            if (!updatedWorkingHours[day].is_active) {
                updatedWorkingHours[day].open_time = null;
                updatedWorkingHours[day].close_time = null;
                updatedWorkingHours[day].has_break = false;
                updatedWorkingHours[day].break_start_time = null;
                updatedWorkingHours[day].break_end_time = null;
            }
            if (!updatedWorkingHours[day].has_break) {
                updatedWorkingHours[day].break_start_time = null;
                updatedWorkingHours[day].break_end_time = null;
            }
        });
        
        const workingHoursJson = JSON.stringify(updatedWorkingHours);
        const formData = new FormData();
    
        formData.append('working_hours', workingHoursJson);

        try {
            await postOnePartner({ formData, partner_id, token });
            toast.success('Dados alterados com sucesso!');
            setIsLoadingButton(false);
            handleCancel();
        } catch (error) {
            toast.error('Erro ao atualizar dados, tente novamente!');
            setIsLoadingButton(false);
        }
    };

    const filterTimeOptions = (start, end) => {
        return timeOptions.filter(time => time >= start && time <= end);
    };

    if (isLoading) {
        return (
            <section id={styles.SectionCompanyGuru}>
                <Container>
                    <Row>
                        <Col className="text-center">
                            <Spinner animation="border" role="status">
                            </Spinner>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    if (isError) {
        return (
            <section id={styles.SectionCompanyGuru}>
                <Container>
                    <Row>
                        <Col>
                            <Alert variant="danger">
                                Erro ao carregar os dados. Por favor, tente novamente mais tarde.
                            </Alert>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    return (
        <HelmetProvider>
            <section id={styles.SectionBussinessHour}>
                <Helmet>
                    <title>Horário de Funcionamento - GoinGuru</title>
                    <meta name="description" content="GoinGuru" />
                </Helmet>
                <Container>
                    <Row>
                        <Col>
                            <p className={styles.title}>Horários de Funcionamento</p>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className={styles.colButtonEdit}>
                            {isEditing ? (
                                <Button className={styles.buttonCancel} onClick={handleCancel}>
                                    Cancelar
                                </Button>
                            ) : (
                                <Button className={styles.buttonEdit} onClick={() => setIsEditing(true)}>
                                    Editar
                                </Button>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        {daysOfWeek.map((day, index) => (
                            <Col xs={12} sm={12} md={6} lg={4} xl={3} key={index} className={styles.colForm}>
                                <Form className={styles.rowForm}>
                                    <Row className={`${styles.rowCard} ${!openCloseState[index] ? styles.noBorder : ''}`}>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={styles.colDay}>
                                            <Col xs={12}>
                                                <p className={styles.dayLabel}>{day}</p>
                                            </Col>
                                            <Col xs={12} className={styles.colDay}>
                                                <Form.Switch
                                                    type='checkbox'
                                                    id={`open-close-${index}`}
                                                    label={openCloseState[index] ? 'Aberto' : 'Fechado'}
                                                    checked={openCloseState[index]}
                                                    onChange={() => handleOpenCloseChange(index)}
                                                    disabled={!isEditing}
                                                />
                                            </Col>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${styles.colTime} ${!openCloseState[index] ? styles.noBorder : ''}`}>
                                            {openCloseState[index] && (
                                                <Row>
                                                    <Col xs={6}>
                                                        <Form.Group controlId={`open-${index}`}>
                                                            <Form.Label className={styles.formTitle}>Aberto às</Form.Label>
                                                            <Form.Select
                                                                className={styles.formControl}
                                                                value={workingHours[dayKeys[index]].open_time || ''}
                                                                onChange={(e) => handleTimeChange(index, 'open_time', e.target.value)}
                                                                disabled={!isEditing}
                                                            >
                                                                {timeOptions.map((time, i) => (
                                                                    <option key={i} value={time}>{time}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Group controlId={`close-${index}`}>
                                                            <Form.Label className={styles.formTitle}>Fechado às</Form.Label>
                                                            <Form.Select
                                                                className={styles.formControl}
                                                                value={workingHours[dayKeys[index]].close_time || ''}
                                                                onChange={(e) => handleTimeChange(index, 'close_time', e.target.value)}
                                                                disabled={!isEditing}
                                                            >
                                                                {timeOptions.map((time, i) => (
                                                                    <option key={i} value={time}>{time}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} className={styles.colCheck}>
                                                        <Form.Check
                                                            type='checkbox'
                                                            id={`lunch-break-${index}`}
                                                            label='Fechado para almoço?'
                                                            className={styles.formSubTitle}
                                                            checked={workingHours[dayKeys[index]].has_break}
                                                            onChange={(e) => handleBreakChange(index, e.target.checked)}
                                                            disabled={!isEditing}
                                                        />
                                                    </Col>
                                                    {workingHours[dayKeys[index]].has_break && (
                                                        <>
                                                            <Col xs={6} className={styles.colFormEnd}>
                                                                <Form.Group controlId={`lunch-start-${index}`}>
                                                                    <Form.Label className={styles.formTitle}>Pausa das</Form.Label>
                                                                    <Form.Select
                                                                        className={styles.formControl}
                                                                        value={workingHours[dayKeys[index]].break_start_time || ''}
                                                                        onChange={(e) => handleTimeChange(index, 'break_start_time', e.target.value)}
                                                                        disabled={!isEditing}
                                                                    >
                                                                        {filterTimeOptions(workingHours[dayKeys[index]].open_time, workingHours[dayKeys[index]].close_time).map((time, i) => (
                                                                            <option key={i} value={time}>{time}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} className={styles.colFormEnd}>
                                                                <Form.Group controlId={`lunch-end-${index}`}>
                                                                    <Form.Label className={styles.formTitle}>até</Form.Label>
                                                                    <Form.Select
                                                                        className={styles.formControl}
                                                                        value={workingHours[dayKeys[index]].break_end_time || ''}
                                                                        onChange={(e) => handleTimeChange(index, 'break_end_time', e.target.value)}
                                                                        disabled={!isEditing}
                                                                    >
                                                                        {filterTimeOptions(workingHours[dayKeys[index]].open_time, workingHours[dayKeys[index]].close_time).map((time, i) => (
                                                                            <option key={i} value={time}>{time}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </Col>
                                                        </>
                                                    )}
                                                </Row>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        ))}
                    </Row>
                    {isEditing && (
                        <Row className={styles.rowButton}>
                            <Col xs={6} md={6} className={styles.colButtonCancel}>
                                <Button className={styles.buttonCancel} onClick={handleCancel}>
                                    Cancelar
                                </Button>
                            </Col>
                            <Col xs={6} md={6} className={styles.colButtonSend}>
                                <Button className={styles.buttonSend} onClick={handleSubmit} disabled={isLoadingButton}>
                                    {isLoadingButton ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Salvar"}
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Container>
            </section>
        </HelmetProvider>
    );
}

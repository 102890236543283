import React from 'react';
import styles from "./style.module.scss"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SectionCompanyGuru from '../../../sections/sectionCompanyGuru';

export default function CompanyGuru() {
  return (
    <HelmetProvider>
      <section id={styles.CompanyGuru}>
        <Helmet>
          <title>Perfil - Parceiro Guru</title>
          <meta name="description" content="GoinGuru" />
        </Helmet>
        <SectionCompanyGuru />
      </section>
    </HelmetProvider>
  );
}

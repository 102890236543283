import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, ListGroup, Form, FloatingLabel, Button, Modal, Spinner } from 'react-bootstrap';
import styles from './style.module.scss';
import { BlogAPI } from '../../api/blog';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Pagination from '../../components/pagination';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useAuthContext } from '../../contexts/authContext';

export default function BlogAuthorPage() {
    const { token } = useAuthContext();
    const [authorName, setAuthorName] = useState('');
    const [errors, setErrors] = useState({});
    const [authors, setAuthors] = useState([]);
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedAuthorId, setSelectedAuthorId] = useState('');
    const [editedauthorName, setEditedauthorName] = useState('');
    const [authorToDelete, setAuthorToDelete] = useState(null);

    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 10; 

    const validateForm = () => {
        const newErrors = {};
        if (!authorName) newErrors.authorName = 'O nome é obrigatório!';
        return newErrors;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            setIsLoadingButton(true);
            try {
                const response = await BlogAPI.blogAuthor(authorName, token);

                if (response.status === 200) {
                    setIsLoadingButton(false);
                    setAuthorName('');
                    getAuthorsData();
                } else {
                    setIsLoadingButton(false);
                    console.error('Erro ao criar Autor:', response.data);
                }
            } catch (error) {
                setIsLoadingButton(false);
                console.error('Erro ao criar Autor:', error.message);
            }
        } else {
            setErrors(formErrors);
        }
    };

    const handleDelete = async () => {
        try {
            const response = await BlogAPI.deleteAuthor(authorToDelete, token);
            if (response.status === 200) {
                getAuthorsData();
                handleDeleteModalClose();
            } else {
                console.error('Erro ao deletar Autor:', response);
            }
        } catch (error) {
            console.error('Erro ao deletar Autor:', error.message);
        }
    };

    const handleDeleteModalOpen = (authorId) => {
        setAuthorToDelete(authorId);
        setDeleteModalOpen(true);
    };

    const handleDeleteModalClose = () => {
        setAuthorToDelete(null);
        setDeleteModalOpen(false);
    };

    const handleEditModalOpen = (authorId, authorName) => {
        setSelectedAuthorId(authorId);
        setEditedauthorName(authorName);
        setEditModalOpen(true);
        setIsLoadingButton(false);
    };

    const handleEditModalClose = () => {
        setSelectedAuthorId('');
        setEditedauthorName('');
        setEditModalOpen(false);
    };

    const handleEditSubmit = async () => {
        setIsLoadingButton(true);
        try {
            const response = await BlogAPI.editAuthor(selectedAuthorId, editedauthorName, token);
            if (response.status === 200) {
                setIsLoadingButton(false);
                handleEditModalClose();
                getAuthorsData();
            } else {
                setIsLoadingButton(false);
                console.error('Erro ao editar Autor:', response);
            }
        } catch (error) {
            setIsLoadingButton(false);
            console.error('Erro ao editar Author:', error.message);
        }
    };

    const getAuthorsData = useCallback(async (page = 1) => {
        const responseCategories = await BlogAPI.getAuhtor(token, page);
        if (responseCategories.status === 200) {
            setAuthors(responseCategories.data.results);
            setTotalPages(Math.ceil(responseCategories.data.count / itemsPerPage));
        }
    }, [token]);

    useEffect(() => {
        getAuthorsData();
    }, [getAuthorsData]);

    const handlePageChange = (page) => {
        getAuthorsData(page);
    };

    return (
        <HelmetProvider>
            <section id={styles.BlogAuthorPage}>
                <Helmet>
                    <title>Autores - Blog</title>
                </Helmet>
                <Container>
                    <Row>
                        <p className={styles.title}>Criar Autores</p>
                    </Row>
                    <Row>
                        <Col lg={4} md={6} sm={6}>
                            <Form className={styles.form}>
                                <FloatingLabel
                                    controlId="floatingauthorName"
                                    label="Nome"
                                >
                                    <Form.Control
                                        className={styles.formLabel}
                                        type="text"
                                        value={authorName}
                                        onChange={(e) => setAuthorName(e.target.value)}
                                        isInvalid={!!errors.authorName}
                                    />
                                    <Form.Control.Feedback className={styles.formControl} type="invalid">
                                        {errors.authorName}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Form>
                        </Col>
                        <Col lg={6} md={4} sm={4}>
                            <Button className={styles.button}  onClick={handleSubmit} disabled={isLoadingButton}>
                                {isLoadingButton ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Salvar"}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <ListGroup className={styles.listGroup}>
                            {authors && authors.length > 0 &&
                                authors.map((author) => (
                                    <ListGroup.Item key={author.id}>
                                        <Row>
                                            <Col lg={10} md={10} sm={10}>
                                                <p className={styles.text}>{author.name}</p>
                                            </Col>
                                            <Col lg={2} md={2} sm={2} className={styles.rowIcon}>
                                                <FaEdit className={styles.iconEdit} onClick={() => handleEditModalOpen(author.id, author.name)} />
                                                <FaTrash className={styles.iconDelete} onClick={() => handleDeleteModalOpen(author.id)} />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                ))
                            }
                        </ListGroup>
                    </Row>
                    <Row>
                        <Pagination totalPages={totalPages} onPageChange={handlePageChange}/>
                    </Row>
                </Container>

                {/* Modal Edit */}
                <Modal show={editModalOpen} onHide={handleEditModalClose} centered className={styles.modal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Editar Categoria</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className={styles.form}>
                            <Form.Group controlId="editauthorName">
                                <Form.Control
                                    className={styles.formLabel}
                                    type="text"
                                    value={editedauthorName}
                                    onChange={(e) => setEditedauthorName(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className={styles.buttonCancel} onClick={handleEditModalClose}>
                            Cancelar
                        </Button>
                        <Button className={styles.buttonSave}  onClick={handleEditSubmit} disabled={isLoadingButton}>
                            {isLoadingButton ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Salvar"}
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Modal Delete */}
                <Modal show={deleteModalOpen} onHide={handleDeleteModalClose} centered className={styles.modal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar Exclusão</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Tem certeza de que deseja excluir este autor?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className={styles.buttonCancel} onClick={handleDeleteModalClose}>
                            Cancelar
                        </Button>
                        <Button className={styles.buttonSave} onClick={handleDelete}>
                            Excluir
                        </Button>
                    </Modal.Footer>
                </Modal>
            </section>
        </HelmetProvider>
    );
}

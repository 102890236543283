import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, ListGroup, Image, Modal, Spinner, Alert } from 'react-bootstrap';
import styles from "./style.module.scss"
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useNavigate, useParams, Link } from 'react-router-dom';
import NoImage from "../../assets/no-image.jpg"
import { PartnerAPI, useEmployeePartnerData } from '../../api/PartnerAPI/partnerClient';
import { useAuthContext } from '../../contexts/authContext';
import Pagination from '../../components/pagination';
import { toast } from 'react-toastify';

export default function SectionEmployeesGuru() {
    const { partner_id } = useParams();
    const { token, user } = useAuthContext();
    const [page, setPage] = useState(1);
    const { data, isLoading, isError, refetch } = useEmployeePartnerData(partner_id, token, page);
    const navigate = useNavigate();
    
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [employeeToDelete, setEmpoloyeeToDelete] = useState(null);

    useEffect(() => {
        refetch(user.id, token, page);
    }, [page, token, user.id, refetch]);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handleDelete = async () => {
        try {
            const response = await PartnerAPI.deleteOneEmployee(employeeToDelete, token);
            if (response.status === 200) {
                refetch(partner_id, token, page);
                handleDeleteModalClose();
                toast.success('Serviço excluído com sucesso!');
            } else {
                toast.error('Erro ao excluir serviço, tente novamente!');
            }
        } catch (error) {
            toast.error('Erro ao excluir serviço, tente novamente!');
        }
    };

    const handleDeleteModalOpen = (employeeId) => {
        setEmpoloyeeToDelete(employeeId);
        setDeleteModalOpen(true);
    };

    const handleDeleteModalClose = () => {
        setEmpoloyeeToDelete(null);
        setDeleteModalOpen(false);
    };

    return (
        <section id={styles.SectionEmployeesGuru}>
            <Container>
                <Row>
                    <Col lg={6} md={6} sm={12}>
                        <p className={styles.title}>Meus Empregados</p>
                    </Col>
                    <Col lg={6} md={6} sm={12} className={styles.colButton}>
                        <Button className={styles.buttonPost} onClick={() => navigate(`/colaboradoresGuru/${partner_id}/criar`)}>
                            Novo Colaborador
                        </Button>
                    </Col>
                </Row>

                <Row className={styles.rowDescription}>
                    <Col xs={3} sm={3} md={2} lg={1} xl={1}>
                        Foto
                    </Col>
                    <Col xs={3} sm={3} md={5} lg={6} xl={6}>
                        Nome
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                        Cargo
                    </Col>
                    <Col xs={4} sm={2} md={2} lg={2} xl={2}>
                        Comissão
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={1} xl={1}>
                    </Col>
                </Row>

                <Row>
                    <ListGroup className={styles.listGroup}>
                        {isLoading && (
                            <ListGroup.Item>
                                <Col className="text-center">
                                    <Spinner animation="border" role="status">
                                    </Spinner>
                                </Col>
                            </ListGroup.Item>
                        )}
                        {isError && (
                            <ListGroup.Item>
                                <Col>
                                    <Alert variant="danger">
                                        Erro ao carregar os dados. Por favor, tente novamente mais tarde.
                                    </Alert>
                                </Col>
                            </ListGroup.Item>
                        )}
                        {!isLoading && !isError && data?.results.length === 0 && (
                            <ListGroup.Item>
                                <Col className="text-center">
                                    <Alert variant="danger">
                                        Não encontrado! Por favor, crie um novo serviço!
                                    </Alert>
                                </Col>
                            </ListGroup.Item>
                        )}
                        {!isLoading && !isError && data?.results.length > 0 && data.results.map(employee => (
                            <ListGroup.Item key={employee.id}>
                                <Row>
                                    <Col xs={3} sm={3} md={2} lg={1} xl={1} className={styles.colImage}>
                                        <Image
                                            src={employee.photo || NoImage}
                                            className={styles.image}
                                        />
                                    </Col>
                                    <Col xs={3} sm={3} md={5} lg={6} xl={6} className={styles.colText}> 
                                        <p className={styles.text}>{employee.name}</p>
                                    </Col>
                                    <Col  className={styles.colSubText}>
                                        <p className={styles.subText}>{employee.role}</p>
                                    </Col>
                                    <Col xs={2} sm={2} md={1} lg={2} xl={2} className={styles.colSubText}>
                                        <p className={styles.subText}>{employee.commission}</p>
                                    </Col>
                                    <Col xs={12} sm={2} md={2} lg={1} xl={1} className={styles.colIcon }>
                                        <Link to={`/colaboradoresGuru/${partner_id}/editar/${employee.id}`}>
                                            <FaEdit className={styles.iconEdit} />
                                        </Link>
                                        <FaTrash className={styles.iconDelete} onClick={() => handleDeleteModalOpen(employee.id)} />
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Row>
                <Row>
                    <Pagination totalPages={data ? Math.ceil(data.count / 10) : 1} onPageChange={handlePageChange}/>
                </Row>
                <Modal show={deleteModalOpen} onHide={handleDeleteModalClose} centered className={styles.modal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar Exclusão</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Tem certeza de que deseja excluir este serviço?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className={styles.buttonCancel} onClick={handleDeleteModalClose}>
                            Cancelar
                        </Button>
                        <Button className={styles.buttonDelete} onClick={handleDelete}>
                            Excluir
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </section>
    );
}

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, FloatingLabel, Spinner } from 'react-bootstrap';
import styles from "./style.module.scss";
import { SignupAPI } from '../../api/signup';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from 'react-toastify';
import MaskedInput from 'react-text-mask';
import { isValidCPF } from '../../utils/utils';

export default function SectionSignUp() {
    const [formValues, setFormValues] = useState({
        name: '',
        birth: '',
        email: '',
        cpf: '',
        password: '',
    });
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [validated, setValidated] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);
    const [showRecaptcha, setShowRecaptcha] = useState(false);
    const [receiveCommunications, setReceiveCommunications] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const cpfMask = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];

    useEffect(() => {
        setShowRecaptcha(
            formValues.name !== '' &&
            formValues.birth !== '' &&
            formValues.email !== '' &&
            formValues.cpf !== '' &&
            formValues.password !== ''
        );
    }, [formValues]);

    const handleLogin = () => {
        navigate('/login');
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formValues.name) newErrors.name = "Nome completo é obrigatório.";
        if (!formValues.email) newErrors.email = "E-mail é obrigatório.";
        if (!formValues.cpf) {
            newErrors.cpf = "CPF é obrigatório.";
        } else if (!isValidCPF(formValues.cpf.replace(/\D/g, ''))) {
            newErrors.cpf = "CPF inválido.";
        }
        if (!formValues.password) newErrors.password = "Senha é obrigatória.";
        if (!formValues.birth) newErrors.birth = "Data de nascimento é obrigatória.";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (!validateForm()) {
            setValidated(true);
            return;
        }

        if (!captchaValue) {
            toast.error('Por favor, resolva o reCAPTCHA!');
            return;
        }

        setIsLoadingButton(true);

        try {
            const response = await SignupAPI.signup(formValues.name, formValues.email, formValues.password, formValues.birth, formValues.cpf);

            if (response.status === 200) {
                toast.success('Cadastro realizado com sucesso!');
                setIsLoadingButton(false);
                navigate('/login');
            } else if (response.status === 400) {
                toast.error('Erro ao fazer cadastro, email ou CPF já utilizado!');
                setIsLoadingButton(false);
            } else if (response.status === 404) {
                toast.error('Erro ao fazer cadastro, verifique o email e senha!');
                setIsLoadingButton(false);
            }
        } catch (error) {
            console.error('Erro ao fazer cadastro:', error.message);
            toast.error('Erro ao fazer cadastro, verifique os dados e tente novamente!');
            setIsLoadingButton(false);
        }
    };

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    return (
        <section id={styles.SectionLogin}>
            <Container className={styles.containerLogin}>
                <Row className="justify-content-center">
                    <Col xs={12} md={6}>
                        <Row className={styles.rowTitle}>
                            <p>Crie sua conta</p>
                        </Row>
                        <Row className={styles.rowForm}>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <FloatingLabel controlId="name" label="Nome Completo">
                                    <Form.Control
                                        type="text"
                                        placeholder="Digite seu nome"
                                        className={styles.formLabel}
                                        name="name"
                                        value={formValues.name}
                                        onChange={handleInputChange}
                                        maxLength="120"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                </FloatingLabel>
                                <FloatingLabel controlId="email" label="Email">
                                    <Form.Control
                                        type="email"
                                        placeholder="Digite seu email"
                                        className={styles.formLabel}
                                        name="email"
                                        value={formValues.email}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                </FloatingLabel>
                                <FloatingLabel controlId="password" label="Senha">
                                    <Form.Control
                                        type="password"
                                        placeholder="Digite sua senha"
                                        className={styles.formLabel}
                                        name="password"
                                        value={formValues.password}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                </FloatingLabel>
                                <FloatingLabel controlId="cpf" label="CPF">
                                    <MaskedInput
                                        mask={cpfMask}
                                        className={styles.formLabel + " form-control"}
                                        name="cpf"
                                        value={formValues.cpf}
                                        onChange={handleInputChange}
                                        render={(ref, props) => (
                                            <Form.Control
                                                ref={ref}
                                                {...props}
                                                isInvalid={!!errors.cpf}
                                            />
                                        )}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.cpf}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                                <FloatingLabel controlId="birth" label="Data de Nascimento">
                                    <Form.Control
                                        type="date"
                                        placeholder="Digite sua data de nascimento"
                                        className={styles.formLabel}
                                        name="birth"
                                        value={formValues.birth}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.birth}</Form.Control.Feedback>
                                </FloatingLabel>
                                <Form.Group controlId="formBasicCheckbox" className={styles.formCheckbox}>
                                    <Form.Check 
                                        type="checkbox" 
                                        label="Gostaria de receber comunicações comerciais da Goinguru (opcional)." 
                                        checked={receiveCommunications}
                                        onChange={(e) => setReceiveCommunications(e.target.checked)}
                                    />
                                </Form.Group>
                                {showRecaptcha && (
                                    <Row className={styles.rowRecaptcha}>
                                        <ReCAPTCHA
                                            sitekey="6LeaPe0pAAAAAG44B5AzphXhmL8IKsmJ9QUTjTsF"
                                            onChange={handleCaptchaChange}
                                            className={styles.recaptcha}
                                        />
                                    </Row>
                                )}
                                <Button className={styles.button} type="submit" disabled={isLoadingButton}>
                                    {isLoadingButton ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Cadastrar"}
                                </Button>
                            </Form>
                        </Row>
                        <Row className={styles.rowTerms}>
                            <p className={styles.termsText}>Ao se registrar, você está de acordo com nossos termos e condições, e confirma estar ciente de nossa política de privacidade.</p>
                        </Row>
                        <Row className={styles.rowSignUp}>
                            <p className={styles.signUpText}>Já tem conta? <strong onClick={handleLogin}>Faça login</strong></p>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

import React from 'react';
import styles from "./style.module.scss"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SectionServicesGuru from '../../../sections/sectionServicesGuru';

export default function ServicesGuruPage() {
  return (
    <HelmetProvider>
      <section id={styles.ServicesGuruPage}>
        <Helmet>
          <title>Meus serviços - GoinGuru</title>
          <meta name="description" content="GoinGuru" />
        </Helmet>
        <SectionServicesGuru />
      </section>
    </HelmetProvider>
  );
}

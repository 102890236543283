import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, ListGroup, Modal, Spinner, Alert } from 'react-bootstrap';
import styles from "./style.module.scss"
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useAuthContext } from '../../contexts/authContext';
import { toast } from 'react-toastify';
import { PartnerAPI, useClientListPartnerData } from '../../api/PartnerAPI/partnerClient';
import Pagination from '../../components/pagination';
import { FaEdit, FaTrash } from 'react-icons/fa';

export default function SectionClientListGuru() {
    const { partner_id } = useParams();
    const { token } = useAuthContext();
    const [page, setPage] = useState(1);
    const { data, isLoading, isError, refetch } = useClientListPartnerData(partner_id, token, page);
    const navigate = useNavigate();

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [clientToDelete, setClientToDelete] = useState(null);

    useEffect(() => {
        refetch(partner_id, token, page);
    }, [page, token, partner_id, refetch]);


    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handleDeleteModalOpen = (employeeId) => {
        setClientToDelete(employeeId);
        setDeleteModalOpen(true);
    };

    const handleDeleteModalClose = () => {
        setClientToDelete(null);
        setDeleteModalOpen(false);
    };

    const handleDelete = async () => {
        try {
            const response = await PartnerAPI.deleteOneClient(clientToDelete, token);
            if (response.status === 200) {
                setPage(1);
                refetch(partner_id, token, page);
                handleDeleteModalClose();
                toast.success('Cliente excluído com sucesso!');
            } else {
                toast.error('Erro ao excluir cliente, tente novamente mais tarde!');
            }
        } catch (error) {
            toast.error('Erro ao excluir cliente, tente novamente mais tarde!');
        }
    };

    return (
        <section id={styles.SectionClientListGuru}>
            <Container>
                <Row>
                    <Col lg={6} md={6} sm={12}>
                        <p className={styles.title}>Lista de Clientes</p>
                    </Col>
                    <Col lg={6} md={6} sm={12} className={styles.colButton}>
                        <Button className={styles.buttonPost} onClick={() => navigate(`/clientesGuru/${partner_id}/criar`)}>
                            Novo Cliente
                        </Button>
                    </Col>
                </Row>

                <Row className={styles.rowDescription}>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                        Nome
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                        Email
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                        WhatsApp
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                    </Col>
                </Row>

                <Row>
                    <ListGroup className={styles.listGroup}>
                        {isLoading && (
                            <ListGroup.Item>
                                <Col className="text-center">
                                    <Spinner animation="border" role="status">
                                    </Spinner>
                                </Col>
                            </ListGroup.Item>
                        )}
                        {isError && (
                            <ListGroup.Item>
                                <Col>
                                    <Alert variant="danger">
                                        Erro ao carregar os dados. Por favor, tente novamente mais tarde.
                                    </Alert>
                                </Col>
                            </ListGroup.Item>
                        )}
                        {!isLoading && !isError && data?.results.length === 0 && (
                            <ListGroup.Item>
                                <Col className="text-center">
                                    <Alert variant="danger">
                                        Não encontrado! Por favor, cadastre um novo cliente!
                                    </Alert>
                                </Col>
                            </ListGroup.Item>
                        )}
                        {!isLoading && !isError && data?.results.length > 0 && data.results.map(client => (
                            <ListGroup.Item key={client.id}>
                                <Row>
                                    <Col xs={12} sm={12} md={4} lg={4} xl={4} className={styles.colText}> 
                                        <p className={styles.text}>{client.name}</p>
                                    </Col>
                                    <Col xs={7} sm={7} md={4} lg={4} xl={4} className={styles.colSubText}>
                                        <p className={styles.subText}>{client.email}</p>
                                    </Col>
                                    <Col xs={5} sm={5} md={2} lg={2} xl={2} className={styles.colSubText}>
                                        <p className={styles.subText}>{client.phone}</p>
                                    </Col>
                                    <Col xs={12} sm={12} md={2} lg={2} xl={2} className={styles.colIcon }>
                                        <Link to={`/clientesGuru/${partner_id}/editar/${client.id}/`}>
                                            <FaEdit className={styles.iconEdit} />
                                        </Link>
                                        <FaTrash className={styles.iconDelete} onClick={() => handleDeleteModalOpen(client.id)}/>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Row>
                <Row>
                    <Pagination totalPages={data ? Math.ceil(data.count / 10) : 1} onPageChange={handlePageChange}/>
                </Row>
                <Modal show={deleteModalOpen} onHide={handleDeleteModalClose} centered className={styles.modal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar Exclusão</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Tem certeza de que deseja excluir este serviço?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className={styles.buttonCancel} onClick={handleDeleteModalClose}>
                            Cancelar
                        </Button>
                        <Button className={styles.buttonDelete} onClick={handleDelete}>
                            Excluir
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </section>
    );
}

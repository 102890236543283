import React from 'react';
import { Container, Row } from 'react-bootstrap';
import styles from './style.module.scss';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function PainelPage() {
  return (
    <HelmetProvider>
      <section id={styles.PainelPage}>
        <Helmet>
            <title>Painel - GoinGuru</title>
        </Helmet>
        <Container>
          <Row className={styles.PainelPage}>
            <h1 className={styles.title}>Bem-vindo ao Painel</h1>
            <p className={styles.description}>Use o menu para criar ou gerenciar sua empresa!.</p>
          </Row>
        </Container>
      </section>
    </HelmetProvider>
  );
}

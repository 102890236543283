import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, ListGroup, Image, Form, Button, Modal, Spinner } from 'react-bootstrap';
import styles from "./style.module.scss";
import { BlogAPI } from '../../api/blog';
import { FaEdit, FaTrash } from 'react-icons/fa';
import NoImage from "../../assets/no-image.jpg";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useAuthContext } from '../../contexts/authContext';

export default function BannersBlogPage() {
    const { token } = useAuthContext();
    const [banners, setBanners] = useState([]);
    const [title, setTitle] = useState('');
    const [order, setOrder] = useState('');
    const [image, setImage] = useState(null);
    const [errors, setErrors] = useState({});
    const [showFirstForm, setShowFirstForm] = useState(false);
    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedBannerId, setSelectedBannerId] = useState(null);
    const [editedTitle, setEditedTitle] = useState('');
    const [editedOrder, setEditedOrder] = useState('');
    const [editedImage, setEditedImage] = useState(null);
    const [bannerToDelete, setBannerToDelete] = useState(null);

    const getBannersData = useCallback(async () => {
        const responseBanners = await BlogAPI.getBannersBlog(token);
        if (responseBanners.status === 200) {
            setBanners(responseBanners.data.results);
        }
    }, [token]);

    useEffect(() => {
        getBannersData();
    }, [getBannersData]);

    const validateForm = () => {
        const newErrors = {};
        if (!title) newErrors.title = 'O título é obrigatório';
        if (!order) newErrors.order = 'A ordem é obrigatória';
        if (!image) newErrors.image = 'A imagem é obrigatória';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) return;

        setIsLoadingButton(true);

        const formData = new FormData();
        formData.append('title', title);
        formData.append('order', order);
        if (image && image instanceof File) {
            formData.append('image', image);
        }

        try {
            const response = await BlogAPI.blogBanners(formData, token);
            if (response.status === 200) {
                setTitle('');
                setOrder('');
                setImage(null);
                setIsLoadingButton(false);
                setShowFirstForm(false);
                getBannersData();
            } else {
                setIsLoadingButton(false);
                console.error('Erro ao criar banner:', response);
            }
        } catch (error) {
            setIsLoadingButton(false);
            console.error('Erro ao criar banner:', error.message);
        }
    };

    const handleDelete = async () => {
        try {
            const response = await BlogAPI.deleteBlogBanners(bannerToDelete, token);
            if (response.status === 200) {
                getBannersData();
                handleDeleteModalClose();
            } else {
                console.error('Erro ao deletar banner:', response);
            }
        } catch (error) {
            console.error('Erro ao deletar banner:', error.message);
        }
    };

    const handleDeleteModalOpen = (bannerId) => {
        setBannerToDelete(bannerId);
        setDeleteModalOpen(true);
    };

    const handleDeleteModalClose = () => {
        setBannerToDelete(null);
        setDeleteModalOpen(false);
    };

    const handleEditModalOpen = (bannerId, bannerTitle, bannerOrder, bannerImage) => {
        setSelectedBannerId(bannerId);
        setEditedTitle(bannerTitle);
        setEditedOrder(bannerOrder);
        setEditedImage(bannerImage);
        setEditModalOpen(true);
        setIsLoadingButton(false);
    };

    const handleEditModalClose = () => {
        setSelectedBannerId(null);
        setEditedTitle('');
        setEditedOrder('');
        setEditedImage(null);
        setEditModalOpen(false);
    };

    const handleEditSubmit = async () => {
        setIsLoadingButton(true);

        const formData = new FormData();
        formData.append('title', editedTitle);
        formData.append('order', editedOrder);
        if (editedImage && editedImage instanceof File) {
            formData.append('image', editedImage);
        }

        try {
            const response = await BlogAPI.editBlogBanners(selectedBannerId, formData, token);
            if (response.status === 200) {
                handleEditModalClose();
                setIsLoadingButton(false);
                getBannersData();
            } else {
                setIsLoadingButton(false);
                console.error('Erro ao editar banner:', response);
            }
        } catch (error) {
            setIsLoadingButton(false);
            console.error('Erro ao editar banner:', error.message);
        }
    };

    return (
        <HelmetProvider>
            <section id={styles.BannersBlogPage}>
                <Helmet>
                    <title>Banners - Blog</title>
                </Helmet>
                <Container className={styles.container}>
                    <Row>
                        <Col>
                            <p className={styles.titlePrincipal}>Banners do Blog</p>
                        </Col>
                        <Col className={styles.colButtonBanner}>
                            <Button onClick={() => setShowFirstForm(!showFirstForm)} className={styles.buttonBanner}>
                                {showFirstForm ? "Cancelar" : "Cadastrar Banner"}
                            </Button>
                        </Col>
                    </Row>
                    {showFirstForm && (
                    <Row className={styles.rowForm}>
                        <Form onSubmit={handleSubmit} className={styles.form} encType="multipart/form-data">
                            <Row>
                                <Col xs={12} md={8}>
                                    <Form.Group controlId="image">
                                        <Form.Label className={styles.formTitle}>Imagem - (1280x320px)</Form.Label>
                                        <Form.Control
                                            className={styles.formControl}
                                            type="file"
                                            onChange={(e) => setImage(e.target.files[0])}
                                            accept=".jpg,.jpeg,.png,.webp"
                                            isInvalid={!!errors.image}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.image}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Row>
                                        <Col xs={12} md={9}>
                                            <Form.Group controlId="title">
                                                <Form.Label className={styles.formTitle}>Título</Form.Label>
                                                <Form.Control
                                                    className={styles.formControl}
                                                    type="text"
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                    maxLength={255}
                                                    isInvalid={!!errors.title}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.title}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Group controlId="order">
                                                <Form.Label className={styles.formTitle}>Ordem</Form.Label>
                                                <Form.Control
                                                    className={styles.formControl}
                                                    type="number"
                                                    value={order}
                                                    onChange={(e) => setOrder(e.target.value)}
                                                    isInvalid={!!errors.order}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.order}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} md={4} className={styles.colImage}>
                                    <Image
                                        src={image ? (image instanceof Blob ? URL.createObjectURL(image) : image) : NoImage}
                                        className={styles.image}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={8}></Col>
                                <Col xs={12} md={4} className={styles.colButton}>
                                    <Button className={styles.button} type="submit" disabled={isLoadingButton}>
                                        {isLoadingButton ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Enviar"}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Row>
                    )}
                    <Row className={styles.rowDescription}>
                        <Col lg={2} md={2} sm={2} className={styles.text}>
                            Imagem
                        </Col>
                        <Col lg={6} md={6} sm={6} className={styles.textTitle}>
                            Título
                        </Col>
                        <Col lg={2} md={2} sm={2} className={styles.text}>
                            Ordem
                        </Col>
                        <Col lg={2} md={2} sm={2} className={styles.text}>
                        </Col>
                    </Row>
                    <Row>
                        <ListGroup className={styles.listGroup}>
                            {banners && banners.length > 0 &&
                                banners.map((banner) => (
                                    <ListGroup.Item key={banner.id}>
                                        <Row>
                                            <Col lg={2} md={2} sm={2} className={styles.colImage}>
                                                <Image src={banner.image} className={styles.image} />
                                            </Col>
                                            <Col lg={6} md={6} sm={6}>
                                                <p className={styles.title}>{banner.title}</p>
                                            </Col>
                                            <Col lg={2} md={2} sm={2}>
                                                <p className={styles.ordem}>{banner.order}</p>
                                            </Col>
                                            <Col lg={2} md={2} sm={2} className={styles.rowIcon}>
                                                <FaEdit className={styles.iconEdit} onClick={() => handleEditModalOpen(banner.id, banner.title, banner.order, banner.image)} />
                                                <FaTrash className={styles.iconDelete} onClick={() => handleDeleteModalOpen(banner.id)} />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                ))
                            }
                        </ListGroup>
                    </Row>
                </Container>

                {/* Modal Edit */}
                <Modal show={editModalOpen} onHide={handleEditModalClose} centered size="lg" className={styles.modal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Editar Banner</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className={styles.form}>
                            <Form.Label className={styles.formTitle}>Título</Form.Label>
                            <Form.Group controlId="editTitle">
                                <Form.Control
                                    className={styles.formLabel}
                                    type="text"
                                    value={editedTitle}
                                    onChange={(e) => setEditedTitle(e.target.value)}
                                    placeholder="Título"
                                />
                            </Form.Group>
                            <Form.Group controlId="editOrder">
                                <Form.Label className={styles.formTitle}>Ordem</Form.Label>
                                <Form.Control
                                    className={styles.formLabel}
                                    type="number"
                                    value={editedOrder}
                                    onChange={(e) => setEditedOrder(e.target.value)}
                                    placeholder="Ordem"
                                />
                            </Form.Group>
                            <Form.Group controlId="editImage">
                                <Form.Label className={styles.formTitle}>Imagem - (1280x320px)</Form.Label>
                                <Form.Control
                                    className={styles.formControl}
                                    type="file"
                                    onChange={(e) => setEditedImage(e.target.files[0])}
                                    accept=".jpg,.jpeg,.png,.webp"
                                />
                            </Form.Group>
                            <div className={styles.divImage}>
                                <Image
                                    src={editedImage ? (editedImage instanceof Blob ? URL.createObjectURL(editedImage) : editedImage) : NoImage}
                                    className={styles.image}
                                />
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className={styles.buttonCancel} onClick={handleEditModalClose}>
                            Cancelar
                        </Button>
                        <Button className={styles.buttonSave}  onClick={handleEditSubmit} disabled={isLoadingButton}>
                            {isLoadingButton ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Salvar"}
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Modal Delete */}
                <Modal show={deleteModalOpen} onHide={handleDeleteModalClose} centered className={styles.modal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar Exclusão</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Tem certeza de que deseja excluir este banner?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className={styles.buttonCancel} onClick={handleDeleteModalClose}>
                            Cancelar
                        </Button>
                        <Button className={styles.buttonSave} onClick={handleDelete}>
                            Excluir
                        </Button>
                    </Modal.Footer>
                </Modal>
            </section>
        </HelmetProvider>
    );
}

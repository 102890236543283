import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, ListGroup, Image, Spinner, Alert, Modal } from 'react-bootstrap';
import styles from "./style.module.scss";
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useNavigate, useParams, Link } from 'react-router-dom';
import NoImage from "../../assets/no-image.jpg";
import { useAuthContext } from '../../contexts/authContext';
import { PartnerAPI, useServicesProvidedData } from '../../api/PartnerAPI/partnerClient';
import Pagination from '../../components/pagination';
import { toast } from 'react-toastify';

export default function SectionServicesGuru() {
    const { partner_id } = useParams();
    const { token } = useAuthContext();
    const [page, setPage] = useState(1);
    const { data, isLoading, isError, refetch } = useServicesProvidedData(partner_id, token, page);
    const navigate = useNavigate();

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [serviceToDelete, setServiceToDelete] = useState(null);

    useEffect(() => {
        refetch(partner_id, token, page);
    }, [page, token, partner_id, refetch]);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handleDelete = async () => {
        try {
            const response = await PartnerAPI.deleteOneServicesProvided(serviceToDelete, token);
            if (response.status === 204) {
                refetch(partner_id, token, page);
                handleDeleteModalClose();
                toast.success('Serviço excluído com sucesso!');
            } else {
                toast.error('Erro ao excluir serviço, tente novamente!');
            }
        } catch (error) {
            toast.error('Erro ao excluir serviço, tente novamente!');
        }
    };

    const handleDeleteModalOpen = (serviceID) => {
        setServiceToDelete(serviceID);
        setDeleteModalOpen(true);
    };

    const handleDeleteModalClose = () => {
        setServiceToDelete(null);
        setDeleteModalOpen(false);
    };

    return (
        <section id={styles.SectionServicesGuru}>
            <Container>
                <Row>
                    <Col>
                        <p className={styles.title}>Meus Serviços</p>
                    </Col>
                    <Col className={styles.colButton}>
                        <Button className={styles.buttonPost} onClick={() => navigate(`/servicosGuru/${partner_id}/criar`)}>
                            Novo Serviço
                        </Button>
                    </Col>
                </Row>

                <Row className={styles.rowDescription}>
                    <Col xs={3} sm={3} md={2} lg={1} xl={1}>Foto</Col>
                    <Col xs={3} sm={3} md={5} lg={4} xl={4}>Serviços</Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>Duração</Col>
                    <Col xs={4} sm={2} md={1} lg={2} xl={2}>Valor</Col>
                    <Col xs={4} sm={2} md={1} lg={2} xl={2}>Tipo</Col>
                    <Col xs={2} sm={2} md={2} lg={1} xl={1}></Col>
                </Row>
                <Row>
                    <ListGroup className={styles.listGroup}>
                        {isLoading && (
                            <ListGroup.Item>
                                <Col className="text-center">
                                    <Spinner animation="border" role="status">
                                    </Spinner>
                                </Col>
                            </ListGroup.Item>
                        )}
                        {isError && (
                            <ListGroup.Item>
                                <Col>
                                    <Alert variant="danger">
                                        Erro ao carregar os dados. Por favor, tente novamente mais tarde.
                                    </Alert>
                                </Col>
                            </ListGroup.Item>
                        )}
                        {!isLoading && !isError && data?.results.length === 0 && (
                            <ListGroup.Item>
                                <Col className="text-center">
                                    <Alert variant="danger">
                                        Não encontrado! Por favor, crie um novo serviço!
                                    </Alert>
                                </Col>
                            </ListGroup.Item>
                        )}
                        {!isLoading && !isError && data?.results.length > 0 && data.results.map(service => (
                            <ListGroup.Item key={service.id}>
                                <Row>
                                    <Col xs={3} sm={3} md={2} lg={1} xl={1} className={styles.colImage}>
                                        <Image
                                            src={service.photo || NoImage}
                                            className={styles.image}
                                        />
                                    </Col>
                                    <Col xs={5} sm={3} md={5} lg={4} xl={4} className={styles.colText}>
                                        <p className={styles.text}>{service.name}</p>
                                    </Col>
                                    <Col xs={4} sm={3} md={2} lg={2} xl={2} className={styles.colText}>
                                        <p className={styles.subText}>{formatDuration(service.duration)}</p>
                                    </Col>
                                    <Col xs={6} sm={3} md={3} lg={2} xl={2} className={styles.colText}>
                                        <p className={styles.subText}>
                                            {service.is_custom_quote ? 'A Combinar' : (service.price ? formatPrice(service.price) : '-')}
                                        </p>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={2} xl={2} className={styles.colText}>
                                        <p className={styles.subText}>
                                            {service.is_home_service
                                                ? 'Domicílio'
                                                : service.is_online_service
                                                ? 'Online'
                                                : 'No Local'}
                                        </p>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={1} xl={1} className={styles.colIcon}>
                                        <Link to={`/servicosGuru/${partner_id}/editar/${service.id}`}>
                                            <FaEdit className={styles.iconEdit} />
                                        </Link>
                                        <FaTrash className={styles.iconDelete} onClick={() => handleDeleteModalOpen(service.id)} />
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Row>
                <Row>
                    <Pagination totalPages={data ? Math.ceil(data.count / 10) : 1} onPageChange={handlePageChange}/>
                </Row>
            </Container>
            <Modal show={deleteModalOpen} onHide={handleDeleteModalClose} centered className={styles.modal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar Exclusão</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Tem certeza de que deseja excluir este serviço?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={styles.buttonCancel} onClick={handleDeleteModalClose}>
                        Cancelar
                    </Button>
                    <Button className={styles.buttonDelete} onClick={handleDelete}>
                        Excluir
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
}

const formatDuration = (duration) => {
    if (!duration) return '-';
    const [hours, minutes] = duration.split(':');
    return `${hours.padStart(2, '0')}h:${minutes.padStart(2, '0')}min`;
};

const formatPrice = (value) => {
    if (!value) return '-';
    const number = parseFloat(value);
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number);
};
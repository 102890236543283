import React from 'react';
import styles from "./style.module.scss"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SectionSchedulesGuru from '../../../sections/sectionSchedulesGuru';

export default function SchedulesGuruPage() {
  return (
    <HelmetProvider>
      <section id={styles.SchedulesGuruPage}>
        <Helmet>
          <title>Meus Agendamentos - GoinGuru</title>
          <meta name="description" content="GoinGuru" />
        </Helmet>
        <SectionSchedulesGuru />
      </section>
    </HelmetProvider>
  );
}
